import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo_dark.svg";
import { Link } from "react-router-dom";
import { getSiteSetting } from "../api/users";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const [SiteData, setSiteData] = useState({});

  const getSiteSettingFunc = async () => {
    try {
      const { status, result } = await getSiteSetting();
      if (status === "success") {
        setSiteData(result);
      }
    } catch (err) {
      console.log("Error on getSiteSettingFunc", err);
    }
  };

  useEffect(() => {
    getSiteSettingFunc();
  }, []);

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel_top">
          <div className="footer_panel_top_left">
            <Link className="navbar-brand" to="/">
              <img src={logo} className="img-fluid footer_logo" alt="logo" />
            </Link>
            <p className="footer_txt">{t("FOOTER_INNOVATIVE_PLATFORM")}</p>
          </div>
          <div className="footer_panel_top_right">
            <p className="footer_txt">{t("FOOTER_FOLLOW_US")}</p>
            <ul className="footer_social_links">
              {SiteData && SiteData.telegramlink &&
                <li><a href={SiteData.telegramlink} target="_blank">
                  <i className="fa-solid fa-paper-plane"></i></a>
                </li>}

              {/* {SiteData && SiteData.linkedinLink &&
                <li><a href={SiteData.linkedinLink} target="_blank">
                  <i className="fab fa-linkedin"></i></a>
                </li>} */}

              {SiteData && SiteData.twitterUrl && <li>
                <a href={SiteData.twitterUrl} target="_blank">
                  <i className="fa-brands fa-x-twitter"></i></a>
              </li>}

              {SiteData && SiteData.facebookLink && <li>
                <a href={SiteData.facebookLink} target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>}
              {SiteData && SiteData.instagramLink && <li>
                <a href={SiteData.instagramLink} target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>}

              {SiteData && SiteData.youtubelink && <li>
                <a href={SiteData.youtubelink} target="_blank">
                  <i className="fab fa-youtube"></i>
                </a>
              </li>}
              {SiteData && SiteData.telegramlink && (
                <li>
                  <a href={SiteData.telegramlink} target="_blank">
                    <i className="fa-solid fa-paper-plane"></i>
                  </a>
                </li>
              )}
              {SiteData && SiteData.twitterUrl && (
                <li>
                  <a href={SiteData.twitterUrl} target="_blank">
                    <i className="fa-brands fa-x-twitter"></i>
                  </a>
                </li>
              )}
              {SiteData && SiteData.facebookLink && (
                <li>
                  <a href={SiteData.facebookLink} target="_blank">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
              )}
              {SiteData && SiteData.youtubelink && (
                <li>
                  <a href={SiteData.youtubelink} target="_blank">
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
              )}
              {SiteData && SiteData.instagramLink && (
                <li>
                  <a href={SiteData.instagramLink} target="_blank">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="footer_panel_bottom">
          <div className="footer_panel_bottom_left">
            <p>
              {t("FOOTER_COPYRIGHT")} <Link to="/">Mantis Network</Link>,{" "}
              {t("FOOTER_ALL_RIGHTS_RESERVED")}.<br />
              {t("FOOTER_ADDRESS")}: {SiteData && SiteData?.address}
            </p>
          </div>
          {SiteData && SiteData?.supportMail && (
            <div>
              {" "}
              {t("FOOTER_CONTACT_US")} :
              <a
                style={{ paddingLeft: 13, textDecoration: "none" }}
                href={`mailto:${SiteData?.supportMail}`}
                target="_blank"
              >
                <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                {SiteData?.supportMail}
              </a>
            </div>
          )}
          <div className="footer_panel_bottom_right">
            <ul className="footer_menu_links">
              <li>
                <Link to="/terms">{t("FOOTER_TERMS_OF_SERVICE")}</Link>
              </li>
              <li>
                <Link to="/privacy">{t("FOOTER_PRIVACY_POLICY")}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
