import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { getTrnxHistory } from "../../api/walletAction";
// import lib
import { dateTimeFormat } from "../../lib/dateTimeHelper";
import { textCopy } from "../../lib/toastAlert";
import {
  capitalize,
  substring,
  toStaringCase,
  upperCase,
} from "../../lib/stringCase";
import { toFixedDown } from "../../lib/roundOf";
// import isEmpty from '../../lib/isEmpty';

import { useTranslation } from "react-i18next";

// Latest Transaction Table Data

const customStyles = {
  headCells: {
    style: {
      fontFamily: "Arial, sans-serif",
      fontSize: "15px", // Change font size
      fontWeight: "600", // Change font size
    },
  },
  cells: {
    style: {
      fontFamily: "Arial, sans-serif",
      fontSize: "15px", // Change font size
    },
  },
  pagination: {
    style: {
      fontFamily: "Arial, sans-serif",
      fontSize: "15px", // Change font size
    },
  },
};

const TransactionHistory = (props) => {
  const { refetch, setValue } = props;
  const { t, i18n } = useTranslation();
  // state
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [pageDoc, setPageDoc] = useState({ page: 1, limit: 10 });

  const { page, limit } = pageDoc;
  const columns = useMemo(
    () => [
      {
        name: `${t("RECENT_ACTIVITY_DATE_LABEL")}`,
        selector: (row) => row.createdAt,
        sortable: true,
        cell: (record) => `${dateTimeFormat(record.createdAt)}`,
      },
      {
        name: `${t("WITHDRAW_AMOUNT")}`,
        selector: (row) => row.amount,
        sortable: true,
        cell: (record) => `${toFixedDown(record.amount, 4)}`,
        // cell: (record) => {
        //   if (record && record.paymentType == 'coin_deposit') {
        //     return <span>{toFixedDown(record.amount, 4)} {record.convertCoin}</span>
        //   } else {
        //     return <span>{toFixedDown(record.amount, 4)}</span>
        //   }
        // }
      },
      {
        name: `${t("FEE")}`,
        selector: (row) => row.fee,
        cell: (record) => {
          return <span>{record && record.fee > 0 ? record.fee : "-"}</span>;
        },
      },
      {
        name: `${t("CURRENCY")}`,
        sortable: true,
        selector: (row) => row.currencySymbol,
        cell: (record) => {
          return (
            <span>
              {record && record.currencySymbol}{" "}
              {record && record.tokenType != ""
                ? upperCase(record.tokenType)
                : ""}
            </span>
          );
        },
      },
      {
        name: `${t("FROM_ADDRESS")}`,
        sortable: false,
        selector: (row) => row.fromAddress,
        cell: (record) => {
          if (record && record.paymentType == "coin_deposit") {
            return (
              <span>
                {substring(record.fromAddress, 6)}{" "}
                <i
                  class="fas fa-copy"
                  onClick={() => textCopy(record.fromAddress)}
                ></i>
              </span>
            );
          } else {
            return <span>-</span>;
          }
        },
      },
      {
        name: `${t("TO_ADDRESS")}`,
        sortable: false,
        selector: (row) => row.toAddress,
        cell: (record) => {
          if (
            (record && record.paymentType == "coin_deposit") ||
            record.paymentType == "coin_withdraw"
          ) {
            return (
              <span>
                {substring(record.toAddress, 6)}{" "}
                <i
                  class="fas fa-copy"
                  onClick={() => textCopy(record.toAddress)}
                ></i>
              </span>
            );
          } else {
            return <span>-</span>;
          }
        },
      },
      {
        name: `${t("TRX_ID")}`,
        sortable: false,
        selector: (row) => row.txid,
        cell: (record) => {
          if (record && record.txid) {
            return (
              <span>
                {substring(record.txid, 6)}{" "}
                <i
                  class="fas fa-copy"
                  onClick={() => textCopy(record.txid)}
                ></i>
              </span>
            );
          } else {
            return <span>-</span>;
          }
        },
      },
      {
        name: `${t("TYPE")}`,
        selector: (row) => `${upperCase(row.paymentType)}`,
        cell: (record) => {
          if (record && record.paymentType) {
            return (
              <span>
                {record?.transferType == "" || record?.transferType == null
                  ? toStaringCase(record?.paymentType)
                  : toStaringCase(record?.transferType)}
              </span>
            );
          } else {
            return <span>-</span>;
          }
        },
      },
      {
        name: `${t("RECENT_ACTIVITY_STATUS_LABEL")}`,
        selector: (row) => `${capitalize(row.status)}`,
      },
    ],
    []
  );
  const getData = async () => {
    try {
      const { status, result } = await getTrnxHistory({ page, limit });
      if (status === "success") {
        setData(result.data);
        setCount(result.count);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getData();
  }, [page, limit]);

  useEffect(() => {
    if (refetch) {
      getData();
      setValue(false);
    }
  }, [refetch]);
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="chart_box">
          <div className="inner_subtitle">
            <h2>{t("TRX_HISTORY_TITLE")}</h2>
          </div>
          <div className="table-responsive primary_table_div">
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationServer
              paginationTotalRows={count}
              onChangePage={(page) =>
                setPageDoc({ ...pageDoc, ...{ page: page } })
              }
              onChangeRowsPerPage={(limit) =>
                setPageDoc({ ...pageDoc, ...{ limit: limit } })
              }
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
