export default {  CUSTOM_SOLUTION: "Maßgeschneiderte Lösung",
  PRIVACY_SECTION_PRIVACY: "Datenschutz",
  LOGIN_LINK_LABEL: "Anmelden",
  REGISTER_LINK_LABEL: "Registrieren",
  CLICK_ME_LABEL: "Klick mich an!",
  LOGIN_EMAIL_PLACEHOLDER: "Geben Sie Ihre E-Mail-Adresse ein",
  EMPOWER_FINANCIAL_FUTURE: "Stärken Sie Ihre finanzielle Zukunft.",
  HOME_DESCRIPTION:
    "Tauchen Sie ein in die Welt der Kryptowährungen mit sachkundiger Anleitung, die darauf abzielt, Ihre Investitionsreise zu beschleunigen. Starten Sie noch heute mit der Investition in Krypto und sehen Sie, wie Ihr Portfolio wächst. Mantis Network bietet Anleitung, um die Grundlagen für einen erfolgreichen Krypto-Investor zu meistern.",
  GET_STARTED: "Loslegen",
  DASHBOARD: "Dashboard",
  ALL_PARTICIPANTS: "Alle Teilnehmer",
  ASSETS_UNDER_MANAGEMENT: "Vermögenswerte im Management",
  WHY_INVEST_WITH_US: "Warum bei uns investieren",
  SECURE_AND_RELIABLE: "Sicher und zuverlässig",
  PLATFORM_SECURITY_BRIEF_EXPLANATION:
    "Unsere Plattform legt Wert auf Sicherheit. Ihre Vermögenswerte sind durch modernste Technologie und branchenführende Sicherheitsprotokolle geschützt.",
  EARN_REWARDS_EFFORTLESSLY: "Belohnungen mühelos verdienen",
  INVESTMENT_DESCRIPTION:
    "Investitionen bei uns bedeuten, dass Sie mühelos Belohnungen in Form zusätzlicher Kryptowährungen verdienen. Keine komplexen Handelsstrategien oder technischen Kenntnisse erforderlich.",
  DECENTRALIZATION_MATTERS: "Dezentralisierung ist wichtig",
  JOIN_THE_MOVEMENT:
    "Schließen Sie sich der Bewegung hin zu einer dezentralen Zukunft an. Durch die Investition in Ihre Krypto tragen Sie aktiv zur Stärke und Stabilität des Blockchain-Netzwerks bei.",
  USER_FRIENDLY_INTERFACE: "Benutzerfreundliche Benutzeroberfläche",
  INTUITIVE_PLATFORM:
    "Unsere intuitive Plattform macht die Investition sowohl für Anfänger als auch für erfahrene Anleger einfach. Keine versteckten Gebühren oder komplizierte Prozesse - einfach ein nahtloses Erlebnis.",
  HOW_IT_WORKS: "So funktioniert's",
  MANTIS_NETWORK_REGISTRATION:
    "Mit Mantis Network müssen Sie sich einfach registrieren und ein Konto erstellen, ohne Kenntnisse von Blockchain oder Krypto zu haben.",
  SIMPLE_PATH_WITH_MANTIS_NETWORK: "Ihr einfacher Weg mit Mantis Network",
  CREATE_YOUR_ACCOUNT: "Erstellen Sie Ihr Konto",
  SIGN_UP_WITH_MANTIS_NETWORK:
    "Melden Sie sich einfach bei Mantis Network an, ohne vorherige Erfahrung mit Blockchain oder Krypto-Wallet. Unsere benutzerfreundliche Plattform ist für alle konzipiert.",
  EXPLORE_INVESTMENT_OPTIONS: "Erkunden Sie Investitionsmöglichkeiten",
  DISCOVER_INVESTMENT_OPPORTUNITIES:
    "Entdecken Sie eine Vielzahl von Investitionsmöglichkeiten, einschließlich Bitcoin-Mining und Ethereum-Staking, maßgeschneiderter Mantis-Strategien, die Ihren finanziellen Zielen entsprechen.",
  BEGIN_INVESTING: "Mit der Investition beginnen",
  START_INVESTMENT_JOURNEY:
    "Mit unserer schrittweisen Anleitung beginnen Sie Ihre Investitionsreise in lukrative Vermögenswerte wie Bitcoin, Ethereum, Altcoins und vielfältige Projekte in der Frühphase.",
  WATCH_PORTFOLIO_GROW: "Sehen Sie Ihr Portfolio wachsen",
  TRACK_INVESTMENTS:
    "Verfolgen Sie Ihre Investitionen mühelos auf Mantis Network und sehen Sie, wie Ihr Portfolio wächst, während Sie tiefer in die Welt der Kryptoinvestitionen eintauchen.",
  ASSET_ALLOCATION: "Vermögensaufteilung",
  FOCUS_ON_INCOME_GENERATION:
    "Konzentrieren Sie sich auf die Einkommensgenerierung, indem Sie täglich BTC (Bitcoin)-Belohnungen in Ihrer digitalen Geldbörse und ETH (Ethereum)-Staking-Belohnungen ansammeln.",
  BTC_MINING_ALLOCATOR_ALLOCATION:
    "40% Allokation für einen BTC-Mining-Allokator",
  BTC_ALLOCATION: "30% Allokation für BTC",
  ETH_ALLOCATION: "20% Allokation für ETH (Ethereum)",
  MANTIS_STRATEGIES_ALLOCATION:
    "10% Allokation für Mantis-Strategien, die Alpha suchen",
  INVESTOR_PORTFOLIO_DESCRIPTION:
    "Dieses Portfolio eignet sich für Anleger, die sich darauf konzentrieren möchten, Einkommen aufzubauen und ihre Renditen mit einer Neigung zur BTC-Preisbelichtung zu steigern.",
  INVESTOR_PORTFOLIO_BLURB:
    "Ausgewogenes Augenmerk auf Einkommensgenerierung und Wachstum",
  GROWTH_FOCUS_BLURB:
    "Fokus auf die Generierung von großem Wachstum mit einer größeren Zuweisung unserer Alpha-suchenden Mantis-Strategien",
  BTC_MINING_ALLOCATOR_ALLOCATION:
    "30% Allokation für einen BTC-Mining-Allokator",
  BTC_ALLOCATION: "20% Allokation für BTC",
  ETH_ALLOCATION: "20% Allokation für ETH (Ethereum)",
  MANTIS_STRATEGIES_ALLOCATION:
    "30% Allokation für Mantis-Strategien, die Alpha suchen",
  INVESTOR_PORTFOLIO_DESCRIPTION:
    "Dieses Portfolio eignet sich für Anleger, die sich darauf konzentrieren möchten, Einkommen aufzubauen und ihre Renditen mit einer Neigung zur BTC-Preisbelichtung zu steigern und gleichzeitig sehr hohe Renditen aus dem Bereich Dezentrale Finanzen und Alternativmünzen anzustreben.",
  INVEST_TITLE: "Investieren",
  BALANCED_TITLE: "Ausgewogen",
  HIGH_YIELD_TITLE: "Hohe Rendite",
  GROWTH_TITLE: "Wachstum",

  ROI_CALCULATOR_MAIN_TITLE: "ROI Rechner",
  ROI_CALCULATOR_INVESTMENTS_LABEL: "Investitionen",
  ROI_CALCULATOR_INVESTMENT_AMOUNT_LABEL: "Investitionsbetrag",
  ROI_CALCULATOR_APY_LABEL: "APY in Prozent (%)",
  ROI_CALCULATOR_MONTHS_LABEL: "Anzahl der Monate",
  ROI_CALCULATOR_INVEST_BUTTON: "Investieren",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_1: "1 Monat",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_3: "3 Monate",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_6: "6 Monate",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_9: "9 Monate",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_12: "12 Monate",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_24: "24 Monate",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_48: "48 Monate",
  ROI_CALCULATOR_INTEREST_LABEL: "Gesamtzinsen:",
  ROI_CALCULATOR_FUTURE_VALUE_LABEL: "Zukünftiger Wert:",
  NEWS_COMPONENT_MAIN_TITLE: "Unsere Nachrichten & Updates",
  NEWS_COMPONENT_VIEW_MORE_BUTTON: "Mehr anzeigen",
  FAQ_COMPONENT_MAIN_TITLE: "Häufig gestellte Fragen",
  FAQ_COMPONENT_VIEW_MORE_BUTTON: "Mehr anzeigen",
  FOOTER_INNOVATIVE_PLATFORM:
    "Mantis Network ist eine innovative Plattform, die es traditionellen Anlegern ermöglicht, einfach in Kryptowährungs-Investitionen einzusteigen.",
  FOOTER_FOLLOW_US: "Folge uns",
  FOOTER_COPYRIGHT: "Urheberrecht ©",
  FOOTER_ALL_RIGHTS_RESERVED: "Alle Rechte vorbehalten.",
  FOOTER_ADDRESS: "Adresse",
  FOOTER_CONTACT_US: "Kontaktiere uns",
  FOOTER_TERMS_OF_SERVICE: "Nutzungsbedingungen",
  FOOTER_PRIVACY_POLICY: "Datenschutz-Bestimmungen",
  INVEST_TITLE: "Investieren",
  INVEST_TABLE_HEADER_ASSETS: "Vermögenswerte",
  INVEST_TABLE_HEADER_WEIGHTS: "Gewichtung",
  INVEST_ALLOCATION_BTC: "BTC",
  INVEST_ALLOCATION_ETH: "ETH",
  INVEST_ALLOCATION_MINING_POOL: "BTC Mining Pool",
  INVEST_ALLOCATION_MANTIS_STRATEGIES: "Mantis Strategies",
  INVEST_ALLOCATION_TOTAL: "Gesamte Zuweisung",
  INVEST_MINIMUM_INVESTMENT: "Mindestinvestition",
  INVEST_PROJECTED_ROI: "Projizierter ROI (%)",
  INVEST_DETAILS: "Details",
  INVEST_INVEST_BUTTON: "Investieren",
  INVEST_INVESTMENT_DETAILS: "Investitionsdetails",
  INVEST_MONTH: "Monat",
  INVEST_INTEREST_RATE: "Zinssatz",
  INVEST_MONTHS: "Monate",
  INVEST_AMOUNT: "Betrag",
  INVEST_INVEST_MODAL_TITLE: "Investitionsauftrag",
  INVEST_INVEST_MODAL_DESCRIPTION:
    "Bitte überprüfen Sie Ihre Investitionsdetails und fahren Sie fort, um den Auftrag zu erteilen.",
  INVEST_INVEST_MODAL_AMOUNT_LABEL: "Betrag",
  INVEST_INVEST_MODAL_PLACE_ORDER: "Auftrag erteilen",
  INVEST_INVEST_MODAL_CLOSE: "Schließen",
  INVEST_RESPONSE_MODAL_TITLE_SUCCESS: "Erfolg",
  INVEST_RESPONSE_MODAL_TITLE_ERROR: "Fehler",
  INVEST_RESPONSE_MODAL_CLOSE: "Schließen",
  INVEST_DASHBOARD_TOTAL_LOCKED_VALUE: "Gesamter gesperrter Wert",
  INVEST_DASHBOARD_CAPITAL_GROWTH: "Kapitalwachstum",
  INVEST_DASHBOARD_YOUR_EARNINGS: "Ihre Einnahmen",
  INVEST_DASHBOARD_AVAILABLE_BALANCE: "Verfügbares Guthaben",
  INVEST_DASHBOARD_WALLET_LINK: "Wallet",
  INVEST_DASHBOARD_INVEST_LINK: "Investieren",

  INVESTMENT_DETAILS_INVESTMENT_DETAILS_TITLE: "Investitionsdetails",
  INVESTMENT_DETAILS_STRATEGY_LABEL: "Strategie",
  INVESTMENT_DETAILS_DURATION_LABEL: "Dauer",
  INVESTMENT_DETAILS_ALL_OPTION: "Alle",
  INVESTMENT_DETAILS_LOW_OPTION: "Niedrig",
  INVESTMENT_DETAILS_HIGH_OPTION: "Hoch",
  INVESTMENT_DETAILS_ID_LABEL: "ID",
  INVESTMENT_DETAILS_TOTAL_INVESTMENT_PERIOD_LABEL: "Gesamte Investitionsdauer",
  INVESTMENT_DETAILS_REWARDS_EARNED_LABEL: "Verdiente Belohnungen",
  INVESTMENT_DETAILS_REMAINING_DAYS_LABEL: "Verbleibende Tage",
  INVESTMENT_DETAILS_CAPITAL_GROWTH_LABEL: "Kapitalwachstum",
  INVESTMENT_DETAILS_VIEW_BUTTON_LABEL: "Ansehen",
  INVESTMENT_DETAILS_INVESTED_AT_LABEL: "Investiert bei",

  RECENT_ACTIVITY_RECENT_ACTIVITY_TITLE: "Letzte Aktivität",
  RECENT_ACTIVITY_SEE_MORE_LINK: "Mehr anzeigen",
  RECENT_ACTIVITY_TRANSFER_TYPE_LABEL: "Überweisung via USD",
  RECENT_ACTIVITY_DEPOSIT_TYPE_LABEL: "Einzahlung via USD",
  RECENT_ACTIVITY_WITHDRAW_TYPE_LABEL: "Abhebung via USD",
  RECENT_ACTIVITY_DATE_LABEL: "Datum",
  RECENT_ACTIVITY_STATUS_LABEL: "Status",
  RECENT_ACTIVITY_REJECTED_STATUS: "Abgelehnt",
  RECENT_ACTIVITY_GREEN_TEXT: "grün_txt",
  RECENT_ACTIVITY_RED_TEXT: "rot_txt",

  FORGOT_PASSWORD_TITLE: "Passwort vergessen",
  FORGOT_PASSWORD_RESET_INFO:
    "Der Link zum Zurücksetzen des Passworts wurde an Ihre registrierte E-Mail-Adresse gesendet",
  FORGOT_PASSWORD_EMAIL_LABEL: "E-Mail-Adresse",
  FORGOT_PASSWORD_SUBMIT_BUTTON: "Absenden",
  FORGOT_PASSWORD_GO_TO_LOGIN: "Zum Login gehen",

  LOGIN_WELCOME_TITLE: "Willkommen bei",
  LOGIN_MANTIS_NETWORK: "Mantis Network",
  LOGIN_SIGN_IN: "Anmelden, um fortzufahren!",
  LOGIN_EMAIL_LABEL: "E-Mail-Adresse",
  LOGIN_PASSWORD_LABEL: "Passwort",
  LOGIN_REMEMBER_ME: "Mich erinnern",
  LOGIN_FORGOT_PASSWORD: "Passwort vergessen?",
  LOGIN_LOGIN_BUTTON: "Anmelden",
  LOGIN_DONT_HAVE_ACCOUNT: "Sie haben noch kein Konto?",
  LOGIN_REGISTER_HERE: "Hier registrieren",
  LOGIN_OTP_VALID_UP_TO: "OTP gültig bis",
  LOGIN_YOUR_OTP_SENT:
    "Ihr Einmalpasswort (OTP) wurde an Ihre registrierte E-Mail-Adresse gesendet",
  LOGIN_ENTER_OTP: "OTP eingeben",
  LOGIN_SUBMIT_BUTTON: "Absenden",
  LOGIN_RESEND_OTP: "OTP erneut senden",

  NEWS_EVENTS_INNER_HEAD_TITLE: "Neuigkeiten und Updates",

  PRIVACY_INNER_HEAD_TITLE: "Datenschutzrichtlinie - Mantis Network",
  PRIVACY_PARAGRAPH_1:
    "Der Datenschutz und die Vertraulichkeit Ihrer persönlichen Daten sind wichtige Anliegen von Mantis Network. Die Nutzung unserer Website ist abhängig von der Erhebung personenbezogener Daten, die bei der Anmeldung und für Ihren Benutzerzugriff erforderlich sind. Mantis Network erfüllt streng alle gesetzlichen Bestimmungen und verarbeitet Ihre hochsensiblen Daten gewissenhaft und verantwortungsbewusst. Wir möchten jedoch darauf hinweisen, dass ein absoluter Schutz der Daten bei der Übertragung im Internet nicht möglich ist und folglich ein vollständiger Schutz vor dem Zugriff Dritter zu keinem Zeitpunkt vollständig vermieden werden kann. Dennoch können Sie davon ausgehen, dass wir alle notwendigen technischen und organisatorischen Sicherheitsmaßnahmen ergreifen, um Ihre persönlichen Daten vor Verlust und Missbrauch zu schützen",
  PRIVACY_SECTION_COOKIES: "Cookies",
  PRIVACY_PARAGRAPH_2:
    "Die Website verwendet PHP-Sitzungscookies. Cookies schaden Ihrem Computer nicht und enthalten keine Viren. Cookies sind ein Mechanismus zur Speicherung von Informationen zur Identifizierung wiederkehrender Benutzer oder zur Verfolgung ihres Pfads bei der Nutzung der Website. Cookies werden verwendet, um unsere Website benutzerfreundlicher, komfortabler und sicherer zu machen. Das Deaktivieren von Cookies kann die Funktionalität dieser Website einschränken",
  PRIVACY_SECTION_LOG_FILES: "Server-LOG-Dateien",
  PRIVACY_PARAGRAPH_3:
    "Unser Server erfasst und speichert automatisch Informationen in sogenannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Die Informationen enthalten keine personenbezogenen Daten. Eine Korrelation dieser Daten mit Ihren persönlichen Daten ist nicht beabsichtigt. Wir behalten uns das Recht vor, diese Daten im Nachhinein zu überprüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden",
  PRIVACY_SECTION_SSL: "SSL-Verschlüsselung",
  PRIVACY_PARAGRAPH_4:
    "Aus Sicherheitsgründen verwendet diese Website SSL-Verschlüsselung für die Übertragung von Daten. Solange SSL-Verschlüsselung aktiviert ist, können Daten während der Übertragung von Dritten nicht gelesen werden",
  PRIVACY_SECTION_CONTACT_FORM: "Kontaktformular",
  PRIVACY_PARAGRAPH_5:
    "Wenn Sie uns eine Nachricht über das 'KONTAKT'-Formular senden, werden Sie mindestens nach Ihrem Namen und Ihrer E-Mail-Adresse gefragt. Diese Details werden zusammen mit der Nachricht zur weiteren Verarbeitung übermittelt und gespeichert. Die Informationen 'Telefon' und 'Adresse' sind freiwillig. Wir werden all diese Informationen ohne Ihre Zustimmung nicht teilen",
  PRIVACY_SECTION_REGISTRATION: "Registrierung",
  PRIVACY_PARAGRAPH_6:
    "Nach der Registrierung speichern wir Ihr Passwort anonym. Nur Ihre E-Mail-Adresse ist für uns sichtbar. Folglich können wir Passwörter nur zurücksetzen, wenn sie vergessen werden. Sie benötigen Ihre E-Mail-Adresse und Ihr Passwort, um sich auf unserer Website anzumelden. Sie sind persönlich verantwortlich für die effektive Geheimhaltung Ihrer Passwörter. Geben Sie die Anmeldedaten nicht an Dritte weiter",
  PRIVACY_SECTION_CONSENT: "Einwilligungserklärung",
  PRIVACY_PARAGRAPH_7:
    "Mit der Teilnahme und Nutzung unserer Dienste erklären Sie sich auch mit der Verarbeitung Ihrer personenbezogenen Daten einverstanden. Die Dienste können ohne die Verarbeitung Ihrer personenbezogenen Daten nicht bereitgestellt werden",
  PRIVACY_SECTION_KYC: "KYC-Konformität",
  PRIVACY_PARAGRAPH_8:
    "Im Hinblick auf EU- und US-Sanktionen sowie das Geldwäschegesetz ist Mantis Network so weit wie möglich verpflichtet, eine KYC- (Know Your Customers) Überprüfung durchzuführen und zu dokumentieren, basierend auf Ihrer Selbstauskunft. Dieses Verfahren erfordert auch einen Identitätsnachweis. Diese Daten werden ohne Ihre vorherige Zustimmung nicht an Dritte weitergegeben, es sei denn, Mantis Network ist gesetzlich verpflichtet, sie bereitzustellen. Natürlich wird Mantis Network seine Kunden sofort kontaktieren, wenn eine Überprüfung zu Unregelmäßigkeiten führt und sie haben das Recht, Stellung zu nehmen",
  PRIVACY_SECTION_THIRD_PARTY: "Dienstleistungen Dritter",
  PRIVACY_PARAGRAPH_9:
    "Mantis Network beauftragt andere Unternehmen und Einzelpersonen für Leistungen, die im Zusammenhang mit unseren Diensten oder geschäftsbezogenen Verwaltungszwecken stehen. Diese Drittanbieter haben nur Zugang, solange und im Umfang, wie sie ihn unbedingt für den Zweck oder für das, wozu sie gesetzlich verpflichtet sind, benötigen",
  PRIVACY_SECTION_INFORMATION: "Recht auf Auskunft",
  PRIVACY_PARAGRAPH_10:
    "Sie haben jederzeit das Recht, Auskunft über Ihre personenbezogenen Daten sowie über den Zweck und die Art der Datenverarbeitung Ihrer Daten zu erhalten. Wenn Sie Fragen zu personenbezogenen Daten haben, können Sie uns jederzeit unter der Adresse kontaktieren, die unter „über uns“ angegeben ist",
  PRIVACY_SECTION_TELEGRAM:
    "Datenschutzrichtlinie für die Nutzung von Telegram",
  PRIVACY_PARAGRAPH_11:
    "Auf unserer Website sind Funktionen des Dienstes Telegram integriert. Diese Funktionen werden von Telegram Messenger LLP angeboten. 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ, UK. Durch die Verwendung des Links zu Telegram können Sie mit Ihrem Telegram-Konto dem 'MANTIS NETWORK Info-Kanal' beitreten. Weitere Informationen finden Sie in der Datenschutzrichtlinie von Telegram unter Ihrem Konto: Telegram FAQ, F:, Datenschutzrichtlinie",
  PRIVACY_SECTION_FACEBOOK:
    "Datenschutzrichtlinie für die Nutzung von Facebook",
  PRIVACY_PARAGRAPH_12:
    "Auf unserer Website sind Funktionen des Dienstes Facebook integriert. Diese Funktionen werden von Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland, angeboten. Durch die Verwendung des Links zu Facebook können Sie unser Konto 'Mantis Network Limited' besuchen. Weitere Informationen finden Sie in der Datenschutzrichtlinie von Facebook; https://www.facebook.com/privacy/explanation",

  PROFILE_PROFILE_TITLE: "Profil",
  PROFILE_USERNAME_LABEL: "Benutzername",
  PROFILE_EMAIL_LABEL: "E-Mail",
  PROFILE_PHONE_NUMBER_LABEL: "Telefonnummer",
  PROFILE_CHANGE_USERNAME_LABEL: "Benutzernamen ändern",
  PROFILE_CHANGE_PASSWORD_LABEL: "Passwort ändern",
  PROFILE_PASSWORD_DESCRIPTION:
    "Dieses Passwort wird für Ihre Anmeldeinformationen verwendet.",
  PROFILE_CHANGE_BUTTON: "Ändern",

  CHANGE_PROFILE_CHANGE_USERNAME_TITLE: "Benutzernamen ändern",
  CHANGE_PROFILE_ENTER_NEW_USERNAME: "Neuen Benutzernamen eingeben",
  CHANGE_PROFILE_CLOSE_BUTTON: "Schließen",
  CHANGE_PROFILE_SAVE_BUTTON: "Speichern",

  CHANGE_PASSWORD_CHANGE_PASSWORD_TITLE: "Passwort ändern",
  CHANGE_PASSWORD_ENTER_OTP: "OTP eingeben",
  CHANGE_PASSWORD_ENTER_OLD_PASSWORD: "Altes Passwort eingeben",
  CHANGE_PASSWORD_ENTER_NEW_PASSWORD: "Neues Passwort eingeben",
  CHANGE_PASSWORD_ENTER_CONFIRM_PASSWORD: "Neues Passwort bestätigen",
  CHANGE_PASSWORD_CLOSE_BUTTON: "Schließen",
  CHANGE_PASSWORD_SEND_BUTTON: "Senden",
  CHANGE_PASSWORD_VERIFY_BUTTON: "Überprüfen",
  CHANGE_PASSWORD_SUBMIT_BUTTON: "Absenden",

  WALLET_DETAILS_TITLE: "Wallet",
  WALLET_DETAILS_TOTAL_BALANCE: "Gesamtbilanz",
  WALLET_DETAILS_INVEST_BUTTON: "Investieren",

  DEPOSIT_TITLE: "Einzahlung",
  DEPOSIT_SELECT_COIN: "Münze auswählen",
  DEPOSIT_SELECT_NETWORK: "Netzwerk auswählen",
  DEPOSIT_ADDRESS_LABEL: "Adresse",
  DEPOSIT_COPY_ICON_ALT: "Kopieren",
  DEPOSIT_GENERATE_ADDRESS_BUTTON: "Adresse generieren",
  DEPOSIT_QR_IMAGE_ALT: "Bild verschwommen",
  DEPOSIT_DEPOSIT_NOTES: "Hinweise zur Einzahlung",
  DEPOSIT_SEND_ONLY:
    "Senden Sie nur {currencySymbol} an diese Einzahlungsadresse.",
  DEPOSIT_MINIMUM_DEPOSIT:
    "Mindesteinzahlung: {minimumDeposit} {currencySymbol}",
  DEPOSIT_SENDING_COIN:
    "Das Senden von Münzen oder Token, die sich von {currencySymbol} unterscheiden, an diese Adresse kann zum Verlust Ihrer Einzahlung führen.",
  WITHDRAW_TITLE: "Abheben",
  WITHDRAW_OTP_VALID_UP_TO: "OTP gültig bis",
  WITHDRAW_OTP_LABEL: "OTP",
  WITHDRAW_ENTER_OTP: "OTP eingeben",
  WITHDRAW_SUBMIT: "Absenden",
  WITHDRAW_RESEND_OTP: "OTP erneut senden",
  WITHDRAW_SELECT_COIN: "Münze auswählen",
  WITHDRAW_SELECT: "Auswählen",
  WITHDRAW_AMOUNT: "Betrag",
  WITHDRAW_ENTER_AMOUNT: "Betrag eingeben",
  WITHDRAW_SELECT_NETWORK: "Netzwerk auswählen",
  WITHDRAW_CONVERSION_RATE: "Wechselkurs",
  WITHDRAW_ENTER_ADDRESS: "Adresse eingeben",
  WITHDRAW_ADDRESS: "Adresse",
  WITHDRAW_WITHDRAW_NOW: "Jetzt abheben",
  WITHDRAW_WITHDRAW_NOTES: "Abheben Hinweise",
  WITHDRAW_SEND_ONLY:
    "Senden Sie nur {{currencySymbol}} an diese Auszahlungsadresse.",
  WITHDRAW_MINIMUM_WITHDRAW:
    "Mindestauszahlung: {{minimumWithdraw}} {{currencySymbol}}",
  WITHDRAW_MAXIMUM_WITHDRAW:
    "Höchstauszahlung: {{maximumWithdraw}} {{currencySymbol}}",
  WITHDRAW_WITHDRAWAL_FEE:
    "Auszahlungsgebühr: {{withdrawFee}} {{currencySymbol}}",
  WITHDRAW_SENDING_COIN:
    "Das Senden von Münzen oder Token, die sich von {{currencySymbol}} unterscheiden, an diese Adresse kann zum Verlust Ihrer Auszahlung führen.",

  TRX_HISTORY_TITLE: "Transaktionsverlauf",
  TERMS_TITLE: "Nutzungsbedingungen",
  TERMS_GENERAL: "1. Allgemeines",
  TERMS_GENERAL_CONTENT_A:
    "a. Diese Allgemeinen Geschäftsbedingungen (nachfolgend „AGB“) gelten für die Nutzung der Hosting-Dienste von Mantis Network (nachfolgend „Dienstleistungen“) der Website http://Mantis.Network (nachfolgend „Website“). Jede Verwendung der in diesen AGB verwendeten Terminologie oder anderer Wörter in der Einzahl, Mehrzahl, Großschreibung und/oder er/sie oder sie/Ihre wird als austauschbar interpretiert und daher als Verweis auf dasselbe betrachtet.",
  TERMS_GENERAL_CONTENT_B:
    "b. Jede natürliche Person, Einrichtung oder andere juristische Person (nachfolgend „Benutzer“), die auf die Website zugreift und die Dienste nutzen wird, muss die AGB zunächst lesen, akzeptieren und bestätigen und sich verpflichten, sich an die Bestimmungen der AGB zu halten, bevor sie Mitglied wird. Jede tatsächliche Teilnahme an den Dienstleistungen stellt eine solche Akzeptanz dar. Wenn der Benutzer diesen AGB nicht zustimmt und sich nicht daran hält, ist ihm die Nutzung der Dienstleistungen nicht gestattet.",
  TERMS_GENERAL_CONTENT_C:
    "c. Jeder Benutzer der Dienstleistungen bestätigt, uneingeschränkt geschäftsfähig (vertraglich befugt) und mindestens achtzehn (18) Jahre alt zu sein, indem er sich für die Dienstleistungen anmeldet. Darüber hinaus ist sich jeder Benutzer aller möglichen Risiken bewusst, die mit der Nutzung der Dienstleistungen, der Blockchain-Technologie und Kryptowährungen verbunden sind.",
  TERMS_EXCLUSIONS_LIMITATIONS_TITLE: "2. Ausschlüsse und Beschränkungen",
  TERMS_EXCLUSIONS_LIMITATIONS_CONTENT_A:
    'a. Die Informationen auf der Website werden im größtmöglichen gesetzlich zulässigen Umfang "wie besehen" bereitgestellt. Der Dienst schließt alle Darstellungen und Gewährleistungen in Bezug auf die Website und deren Inhalte oder Inhalte, die von verbundenen Unternehmen oder anderen Dritten bereitgestellt werden können, aus, einschließlich etwaiger Ungenauigkeiten oder Auslassungen in Bezug auf die Website und/oder die Dienstleistungen sowie die damit verbundenen Darstellungen, Vorführungen und Beschreibungen.',
  TERMS_EXCLUSIONS_LIMITATIONS_CONTENT_B:
    "b. Die Betriebszeit des Dienstes oder der Website ist nicht garantiert; Wartungsarbeiten und Ausfallzeiten können von Zeit zu Zeit erforderlich sein. Der Eigentümer ist nicht verantwortlich für den kontinuierlichen und störungsfreien Betrieb und die Sicherheit der Kryptowährungsnetzwerke. Der Eigentümer wird jedoch sein Bestes tun, um den Dienst unter guten Bedingungen bereitzustellen und etwaige schlechtere Folgen zu vermeiden.",
  TERMS_LIMITATION_LIABILITIES_TITLE: "3. Haftungsbeschränkung",
  TERMS_LIMITATION_LIABILITIES_CONTENT_A:
    "a. Mantis Network Ltd., seine gesetzlichen Vertreter und Aktionäre (nachfolgend gemeinsam „Eigentümer“) haften nicht für direkte oder indirekte, Folge- und zufällige Schäden, die mit der Website und/oder den Dienstleistungen in Zusammenhang stehen oder durch sie verursacht werden, unabhängig davon, ob die Folgen vorhersehbar waren oder nicht und in einem normalen Fall wie Dinge passieren können.",
  TERMS_LIMITATION_LIABILITIES_CONTENT_B:
    "b. Insbesondere haftet der Eigentümer nicht für Schäden wie, aber nicht beschränkt auf Schäden an der Hardware oder Software des Computers des Benutzers, Systeme und Programmierung oder Datenverlust sowie wirtschaftlichen Verlust, Geschäftsverlust oder Gewinnverlust.",
  TERMS_LIMITATION_LIABILITIES_CONTENT_C:
    "c. Der Benutzer bestätigt das Bewusstsein für alle Risiken, einschließlich der Möglichkeit wirtschaftlichen Verlusts oder des Verlusts erwarteter Gewinne. Der Eigentümer wird keinen irreversiblen Verlust kompensieren.",
  TERMS_PAYOUTS_TITLE: "4. Auszahlungen",
  TERMS_PAYOUTS_CONTENT_A:
    "a. Mantis Network wird Belohnungen proportional zum vom Benutzer getätigten Investitionsbetrag auszahlen. Alle Transaktionen werden in der Währung USDT abgewickelt.",
  TERMS_PAYOUTS_CONTENT_B:
    "b. Die Auszahlungen erfolgen AUSSCHLIESSLICH auf das im Investitionsformular (Dashboard) vom Benutzer angegebene Konto. Die Auszahlungen erfolgen regelmäßig alle vier (4) Wochen.",
  TERMS_PAYOUTS_CONTENT_C:
    "c. Der Eigentümer berechnet für die Dienstleistungen eine Beitrittsgebühr von 2% auf alle Einzahlungen und eine Gebühr von 25% auf alle Belohnungen von Mantis Network.",
  TERMS_PAYOUTS_CONTENT_D:
    "d. Alle von einem bestimmten Kryptowährungskonto angesammelten Belohnungen werden nur an diese bestimmte Adresse gezahlt. Unterschiedliche Kontostände können nicht fusioniert werden.",
  TERMS_PAYOUTS_CONTENT_E:
    "e. Der Eigentümer haftet nicht für Änderungen im Wert von Kryptowährungen. Die Return of Investment (ROI) wird auf Grundlage der tatsächlichen Belohnungen für jeden Block der letzten 24 Stunden berechnet. Der Eigentümer kann nicht garantieren, dass eine Kryptowährung einen konstanten Wert behält und kann daher die ROI nicht garantieren. Ein Gesamtverlust ist jederzeit möglich, und jedes Risiko wird von jedem Benutzer getragen.",
  TERMS_PAYOUTS_CONTENT_F:
    "f. Der Portfolio-Wert kann einmal pro Quartal kostenlos für die Berechnung über den Support angefordert werden, maximal 4 Wochen vor Ablauf des Haltezeitraums. Jede weitere Berechnung wird aufgrund des hohen Aufwands mit 25 $ in USDT im Voraus berechnet und muss bezahlt werden. Der Portfolio-Wert hat eine Gültigkeit von jeweils 7 Tagen. Aus betrieblichen Gründen und aufgrund der hohen und wiederkehrenden Anfragen nach dem Portfolio-Wert kann es sein, dass eine Poolwertberechnung nur einmal pro Quartal, maximal 4 Wochen vor Ablauf des Haltezeitraums, durchgeführt und weitergegeben wird.",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_TITLE:
    "5. Rechtliche Anforderungen und Sanktionen",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_A:
    "a. Der Benutzer erklärt durch seine Zustimmung zu diesen AGB, dass er von keiner Sanktion betroffen ist oder von einer zuständigen Aufsichtsbehörde gelistet wurde. Änderungen muss er unverzüglich mitteilen. Der Eigentümer wird seinen Status regelmäßig überwachen. Ein positives Screening-Ergebnis führt zur sofortigen Einstellung der Dienstleistungen.",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_B:
    "b. Darüber hinaus bestätigt der Benutzer mit seiner Zustimmung zu den AGB, dass er auf eigene Kosten handelt.",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_C:
    "c. Der Eigentümer ist berechtigt, einen Identitätsnachweis vom Benutzer zu verlangen. Bei natürlichen Personen handelt es sich in der Regel um ein offizielles Dokument einer Behörde (z.B. Personalausweis), im Falle einer juristischen Person um einen aktuellen Auszug aus dem Handelsregister der Aufsichtsbehörden.",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_D:
    "d. Im Falle von falschen oder unvollständigen Informationen sowie Betrug oder betrügerischer Täuschung hat der Benutzer den Eigentümer von allen rechtlichen Konsequenzen und finanziellen Schäden freizustellen. Darüber hinaus wird der Eigentümer den Benutzer sofort von der Nutzung der Website und der Dienstleistungen ausschließen. Der Eigentümer behält sich das Recht vor, rechtliche Schritte einzuleiten.",
  TERMS_WAIVER_TITLE: "6. Verzicht",
  TERMS_WAIVER_CONTENT:
    "Kein Verzicht auf eine der Bestimmungen der AGB gilt als Verzicht auf eine andere Bestimmung, noch stellt ein solcher Verzicht einen fortgesetzten Verzicht dar, es sei denn, dies ist ausdrücklich schriftlich durch die zu bindende Partei vorgesehen.",
  TERMS_FORCE_MAJEURE_TITLE: "7. Höhere Gewalt",
  TERMS_FORCE_MAJEURE_CONTENT:
    "Der Eigentümer haftet nicht in jedem Ereignis oder Umstand, der außerhalb der Kontrolle des Eigentümers liegt, einschließlich, aber nicht beschränkt auf höhere Gewalt, Unruhen, Kriege, Überschwemmungen, Brände, Explosionen, Streiks und andere ähnliche Ereignisse, die die Bereitstellung bereits zugesagter Dienstleistungen verhindern, verzögern, stören oder fehlschlagen.",
  TERMS_TERMINATION_TITLE: "8. Beendigung",
  TERMS_TERMINATION_CONTENT_A:
    "Die Zustimmung des Benutzers zu den AGB umfasst eine Investition in das Mantis Network. Die Mindestsperrfrist des Portfolios gilt für einen Zeitraum von (180) einhundertachtzig Kalendertagen und verlängert sich automatisch, wenn der Benutzer die AGB nicht bis zum Ende der Mindestsperrfrist kündigt. Der Benutzer kann seine AGB und die Nutzung der Dienstleistungen durch Senden einer E-Mail an support@Mantis.Network beenden. Der Anteil am aktuellen Mantis Network Portfolio-Wert wird ausgezahlt. Der Eigentümer hat ein außerordentliches Kündigungsrecht, wenn zwingende Gründe gegen eine Fortsetzung der Geschäftsbeziehung mit einem Benutzer sprechen. Im Falle einer vorzeitigen außerordentlichen Kündigung des Vertrags wird der Anteil am aktuellen Mantis Network-Portfolio-Wert, abzüglich einer Bearbeitungsgebühr von 25%, erstattet. Es besteht kein Anspruch auf unbezahlte oder zukünftige Auszahlungen.",
  TERMS_SEVERABILITY_CLAUSE_TITLE: "9. Salvatorische Klausel",
  TERMS_SEVERABILITY_CLAUSE_CONTENT:
    "Sollte eine Bestimmung der AGB ungültig werden, berührt die Ungültigkeit andere Bestimmungen der AGB nicht, die ohne die ungültige Bestimmung wirksam sein können, und zu diesem Zweck sind die Bestimmungen der AGB abtrennbar.",
  TERMS_GOVERNING_LAW_TITLE: "10. Rechtswahl",
  TERMS_GOVERNING_LAW_CONTENT:
    "Die Rechte und Pflichten des Eigentümers und des Benutzers sowie Dritter, sofern vorhanden, gemäß den AGB, der Website und den Dienstleistungen unterliegen den Gesetzen der Britischen Jungferninseln. Etwaige Streitigkeiten, Kontroversen oder Ansprüche aus oder im Zusammenhang mit den AGB, der Website und den Dienstleistungen werden endgültig gemäß den Schiedsgerichtsregeln der Internationalen Handelskammer (Paris, Frankreich) beigelegt. Das Schiedsverfahren wird in Tortola, Britische Jungferninseln, durchgeführt. Die Verhandlungssprache ist Englisch.",

  RESET_PASSWORD_TITLE: "Passwort zurücksetzen",
  RESET_PASSWORD_NEW_LABEL: "Neues Passwort",
  RESET_PASSWORD_CONFIRM_LABEL: "Passwort bestätigen",
  RESET_PASSWORD_PASSWORD_PLACEHOLDER: "Passwort eingeben",
  RESET_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER: "Passwort bestätigen eingeben",
  RESET_PASSWORD_SHOW_PASSWORD: "Passwort anzeigen",
  RESET_PASSWORD_SHOW_CONFIRM_PASSWORD: "Bestätigungs-Passwort anzeigen",
  RESET_PASSWORD_PASSWORD_ERROR: "Bitte geben Sie ein gültiges Passwort ein",
  RESET_PASSWORD_CONFIRM_PASSWORD_ERROR: "Passwörter stimmen nicht überein",
  RESET_PASSWORD_SUBMIT_BUTTON: "Absenden",

  REGISTER_USER_NAME: "Benutzername",
  REGISTER_ENTER_USERNAME: "Benutzernamen eingeben",
  REGISTER_EMAIL_ADDRESS: "E-Mail-Adresse",
  REGISTER_ENTER_EMAIL: "Ihre E-Mail eingeben",
  REGISTER_COUNTRY: "Land",
  REGISTER_PHONE_NUMBER: "Telefonnummer",
  REGISTER_ENTER_MOBILE_NUMBER: "Ihre Mobilnummer eingeben",
  REGISTER_PASSWORD: "Passwort",
  REGISTER_ENTER_PASSWORD: "Passwort eingeben",
  REGISTER_CONFIRM_PASSWORD: "Passwort bestätigen",
  REGISTER_ENTER_CONFIRM_PASSWORD: "Passwort bestätigen eingeben",
  REGISTER_REFERRAL_CODE: "Empfehlungscode (optional)",
  REGISTER_ENTER_REFERRAL_CODE: "Empfehlungscode eingeben",
  REGISTER_OTP: "OTP",
  REGISTER_ENTER_OTP: "OTP eingeben",
  REGISTER_AGREE_TERMS: "Ich verstehe",
  REGISTER_TERMS_LINK: "Nutzungsbedingungen",
  REGISTER_PRIVACY_LINK: "Datenschutzrichtlinie",
  REGISTER_BUTTON: "Registrieren",
  REGISTER_ALREADY_REGISTER: "Bereits registriert?",
  REGISTER_SIGN_IN: "Anmelden",

  REFERRAL_HEAD_TITLE: "Empfehlung",
  REFERRAL_TOTAL_REFERRALS_LABEL: "Gesamtzahl der Empfehlungen",
  REFERRAL_TOTAL_INCOME_LABEL: "Insgesamt verdientes Empfehlungseinkommen",
  REFERRAL_TOTAL_USERS_INVEST_LABEL:
    "Insgesamt empfohlene Benutzer investieren",
  REFERRAL_LINK: "Empfehlungslink",
  REFERRAL_LEVEL_INCOME: "Ebenen-Einkommen",
  REFERRAL_LEVEL_1_INCOME: "Ebenen-1-Einkommen",
  REFERRAL_LEVEL_2_INCOME: "Ebenen-2-Einkommen",
  REFERRAL_LEVEL_3_INCOME: "Ebenen-3-Einkommen",
  REFERRAL_HISTORY_LABEL: "Empfehlungsverlauf",

  // BACKEND

  EMAIL_EXISTS: "E-Mail existiert bereits",
  PHONE_EXISTS: "Telefonnummer existiert bereits",
  INVALID_REFERRAL_CODE: "Ungültiger Empfehlungscode",
  OTP_SENT:
    "OTP wurde an Ihre E-Mail-Adresse gesendet, bitte geben Sie ein OTP ein",
  EXPIRED_OTP: "Abgelaufenes OTP",
  INVALID_OTP: "Ungültiges OTP",
  REGISTRATION_SUCCESS:
    "Registrierung abgeschlossen - Aktivierungslink wurde an Ihre E-Mail-Adresse gesendet",
  SOMETHING_WENT_WRONG: "Etwas ist schief gelaufen",

  INVALID_EMAIL: "Bitte geben Sie eine korrekte E-Mail-Adresse ein",
  PHONE_NOT_EXISTS: "Telefonnummer existiert nicht",
  IP_BLOCKED: "Ihre IP wurde blockiert",
  ACCOUNT_LOCKED: "Ihr Konto ist immer noch gesperrt",
  ACCOUNT_BLOCKED:
    "Ihr Konto wurde gesperrt. Bitte versuchen Sie es später erneut",
  ACCOUNT_NOT_ACTIVATED: "Ihr Konto ist noch nicht aktiviert",
  LOGIN_ATTEMPTS_EXCEEDED:
    "Sie haben zu viele Anmeldeversuche unternommen. Bitte versuchen Sie es nach einigen Minuten erneut.",
  PASSWORD_INCORRECT: "Falsches Passwort",
  OTP_SENT:
    "OTP an Ihre E-Mail-Adresse gesendet, OTP ist nur 3 Minuten lang gültig",
  INVALID_MOBILE_NUMBER: "Ungültige Mobilnummer",
  OTP_SENT_MOBILE: "OTP erfolgreich gesendet, es ist nur 10 Minuten gültig",
  OTP_EXPIRED: "OTP abgelaufen",
  INVALID_OTP: "Ungültige OTP",
  NEED_TWO_FA: "Benötigen 2FA-Code",
  INVALID_TWO_FA: "Ungültiger 2FA-Code",
  EXPIRED_TWO_FA: "Abgelaufener 2FA-Code",
  OTP_IS_REQUIRED: "OTP ist erforderlich",
  LOGIN_SUCCESS: "Anmeldung erfolgreich!",
  ERROR_SERVER: "Fehler auf dem Server",

  EMAIL_NOT_EXISTS: "E-Mail existiert nicht",
  CONFIRMATION_LINK_SENT:
    "Bestätigungslink wurde an Ihre registrierte E-Mail gesendet",
  SOMETHING_WENT_WRONG: "Etwas ist schief gelaufen",

  INVALID_LINK: "Ungültiger Link!",
  OLD_PASSWORD_CANT_BE_NEW_PASSWORD:
    "Das alte Passwort kann nicht das neue Passwort sein!",
  PASSWORD_UPDATED_SUCCESSFULLY: "Passwort erfolgreich aktualisiert",
  SOMETHING_WENT_WRONG: "Etwas ist schief gelaufen",

  USER_NOT_FOUND: "Benutzer nicht gefunden",
  INCORRECT_OLD_PASSWORD: "Falsches altes Passwort",
  NEW_PASSWORD_CANT_BE_OLD_PASSWORD:
    "Das neue Passwort kann nicht das alte Passwort sein!",
  PASSWORD_CHANGED_SUCCESSFULLY: "Passwort erfolgreich geändert",

  PROFILE_DETAILS_UPDATED_SUCCESSFULLY:
    "Profilinformationen erfolgreich aktualisiert",
  PROFILE_UPDATED_SUCCESSFULLY: "Profil erfolgreich aktualisiert",
  SOMETHING_WENT_WRONG: "Etwas ist schief gelaufen",

  DEACTIVATED: "Deaktiviert",
  ACTIVATED: "Aktiviert",
  USER_STATUS_CHANGED_SUCCESSFULLY: "Benutzerstatus erfolgreich geändert",
  SOMETHING_WENT_WRONG: "Etwas ist schief gelaufen",
  ACTIVATE_REGISTER_USER: "activate_register_user",
  ACTIVATION_MAIL_SENT_SUCCESSFULLY: "Aktivierungsmail erfolgreich gesendet",
  ERROR_ON_SERVER: "Fehler auf dem Server",
  NO_RECORD: "Kein Eintrag",
  UPDATED_SUCCESS: "Aktualisierung erfolgreich",
  INVALID_USER: "Ungültiger Benutzer",
  INVALID_MOBILE_NO: "Geben Sie eine korrekte Mobilnummer ein",
  RESEND_OTP_MOBILE:
    "Bestätigungscode an Ihre Mobilnummer gesendet, Bestätigungscode ist nur 10 Minuten gültig",
  NEXT_OTP_AFTER_3_MINUTES: "Nächster Bestätigungscode nach 3 Minuten",
  RESEND_OTP_EMAIL:
    "Bestätigungscode an Ihre E-Mail-Adresse gesendet, Bestätigungscode ist nur 3 Minuten gültig",
  NAME_FIELD_REQUIRED: "Name Feld ist erforderlich",
  USERNAME_ALPHABETS_SPACES:
    "Benutzername sollte Buchstaben und Leerzeichen enthalten",
  USER_NOT_FOUND: "Benutzer nicht gefunden",
  PROFILE_UPDATED_SUCCESSFULLY: "Profil erfolgreich aktualisiert",
  INVALID_USER_DETAILS: "Ungültige Benutzerdetails",
  INVALID_TYPE: "Ungültiger Typ",
  CODE_VERIFIED: "Code überprüft",
  ERROR_ON_SERVER: "Fehler auf dem Server",
  INVALID_VERIFICATION_CODE: "Ungültiger Überprüfungscode",
  VERIFICATION_CODE_EXPIRED: "Überprüfungscode abgelaufen",
  CODE_VERIFIED: "Code überprüft",
  SOMETHING_WENT_WRONG_TRY_AGAIN_LATER:
    "Etwas ist schief gelaufen, versuchen Sie es später erneut",
  REQUIRED: "Erforderlich",
  USERNAME_ALPHA_SPACE:
    "Benutzername darf nur Buchstaben und Leerzeichen enthalten",
  EMAIL_REQUIRED: "E-Mail-Feld ist erforderlich",
  INVALID_EMAIL: "Ungültige E-Mail",
  PHONE_NO_REQUIRED: "Telefonnummer-Feld ist erforderlich",
  COUNTRY_CODE_REQUIRED: "Ländercode-Feld ist erforderlich",
  PASSWORD_REQUIRED: "Passwort-Feld ist erforderlich",
  INVALID_PASSWORD_FORMAT:
    "Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl, ein Sonderzeichen enthalten und eine Länge zwischen 6 und 18 Zeichen haben",
  PASSWORD_LENGTH_EXCEEDED:
    "Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl, ein Sonderzeichen enthalten und eine Länge zwischen 6 und 18 Zeichen haben",
  CONFIRM_PASSWORD_REQUIRED:
    "Bestätigen Sie das Passwort-Feld ist erforderlich",
  PASSWORD_MISMATCH:
    "Passwort und Bestätigen Sie das Passwort müssen übereinstimmen",
  TERMS_REQUIRED:
    "Feld Allgemeine Geschäftsbedingungen und Datenschutzrichtlinie ist erforderlich",
  USERID_FIELD_IS_REQUIRED: "Das UserID-Feld ist erforderlich",
  FIRST_NAME_FIELD_IS_REQUIRED: "Das Vorname-Feld ist erforderlich",
  LAST_NAME_FIELD_IS_REQUIRED: "Das Nachname-Feld ist erforderlich",
  PHONE_CODE_FIELD_IS_REQUIRED: "Das Telefonvorwahl-Feld ist erforderlich",
  PHONE_NUMBER_FIELD_IS_REQUIRED: "Das Telefonnummer-Feld ist erforderlich",
  PHONE_NUMBER_IS_INVALID: "Telefonnummer ist ungültig",
  ADDRESS_FIELD_IS_REQUIRED: "Das Adress-Feld ist erforderlich",
  COUNTRY_FIELD_IS_REQUIRED: "Das Land-Feld ist erforderlich",
  CITY_FIELD_IS_REQUIRED: "Das Stadt-Feld ist erforderlich",
  POSTAL_CODE_FIELD_IS_REQUIRED: "Das Postleitzahl-Feld ist erforderlich",
  OLD_PASSWORD_FIELD_IS_REQUIRED: "Altes Passwortfeld ist erforderlich",
  NEW_PASSWORD_FIELD_IS_REQUIRED: "Neues Passwortfeld ist erforderlich",
  PASSWORD_REQUIREMENTS_NOT_MET:
    "Das Passwort muss mindestens einen Großbuchstaben, mindestens einen Kleinbuchstaben, mindestens eine Zahl, mindestens ein Sonderzeichen enthalten und zwischen 6 und 18 Zeichen lang sein",
  CONFIRM_NEW_PASSWORD_FIELD_IS_REQUIRED:
    "Bestätigung des neuen Passwortfeldes ist erforderlich",
  PASSWORD_AND_CONFIRM_PASSWORD_MUST_MATCH:
    "Das neue Passwort und die Bestätigung des Passworts müssen übereinstimmen",
  OLD_AND_NEW_PASSWORD_MUST_BE_DIFFERENT:
    "Das alte und das neue Passwort müssen unterschiedlich sein",
  AUTH_TOKEN_FIELD_IS_REQUIRED: "Authentifizierungstokenfeld ist erforderlich",
  CONFIRM_PASSWORD_FIELD_IS_REQUIRED:
    "Bestätigung des Passwortfeldes ist erforderlich",
  NEW_AND_CONFIRM_PASSWORD_MUST_MATCH:
    "Das neue Passwort und die Bestätigung des Passworts müssen übereinstimmen",
  USER_ASSET_FIELD_IS_REQUIRED: "Benutzerasset-Feld ist erforderlich",
  INVALID_TRANSACTION_ID: "Ungültige Transaktions-ID",
  REQUIRED: "Erforderlich",
  AMOUNT_FIELD_IS_REQUIRED: "Betragsfeld ist erforderlich",
  INVALID_AMOUNT: "Ungültiger Betrag",
  USER_ASSET_FIELD_IS_REQUIRED: "Benutzer-Asset-Feld ist erforderlich",
  INVALID_CURRENCY: "Ungültige Währung",
  AMOUNT_FIELD_IS_REQUIRED: "Menge Feld ist erforderlich",
  INVALID_TRANSACTION_ID: "Ungültige Transaktions-ID",
  REQUIRED: "Erforderlich",
  INVALID_CURRENCY_ID: "Ungültige Währungs-ID",
  RECEIVER_ADDRESS_FIELD_IS_REQUIRED: "Empfängeradressfeld ist erforderlich",
  INVALID_ADDRESS: "Ungültige Adresse",
  AMOUNT_FIELD_IS_REQUIRED: "Betragfeld ist erforderlich",
  PLEASE_ENTER_VALID_AMOUNT: "Bitte geben Sie einen gültigen Betrag ein",
  MIN_WITHDRAW_AMOUNT_MESSAGE:
    "Bitte geben Sie einen Auszahlungsbetrag ein, der größer oder gleich ist als",
  OTP_STATUS_IS_REQUIRED: "OTP-Status ist erforderlich",
  INVALID_OTP_STATUS: "Ungültiger OTP-Status",
  PLEASE_ENTER_OTP: "Bitte geben Sie einen OTP ein",
  THERE_IS_NO_DATA: "Es liegen keine Daten vor",
  PLEASE_ENTER_AMOUNT_GREATER_THAN_OR_EQUAL_TO:
    "Bitte geben Sie den Betrag größer oder gleich ",
  DEPOSIT_REQUEST_SENT_SUCCESSFULLY: "Einzahlungsanfrage erfolgreich gesendet",
  INVALID_CURRENCY_DATA: "Ungültige Währungsdaten",
  INVALID_WITHDRAW_CURRENCY_DATA: "Ungültige Abhebungs-Währungsdaten",
  MIN_WITHDRAW_AMOUNT_REQUIRED:
    "Mindestauszahlungsbetrag muss größer oder gleich sein als ",
  MAX_WITHDRAW_AMOUNT_REQUIRED:
    "Abhebungsbetrag muss kleiner oder gleich sein als ",
  INSUFFICIENT_BALANCE: "Unzureichendes Guthaben",
  WITHDRAW_AMOUNT_TOO_LOW: "Abhebungsbetrag zu niedrig",
  OTP_SENT_SUCCESSFULLY:
    "OTP an Ihre E-Mail-Adresse gesendet, Bitte geben Sie einen OTP ein",
  EXPIRED_OTP: "Abgelaufener OTP",
  INVALID_OTP: "Ungültiger OTP",
  WITHDRAW_REQUEST_SENT_SUCCESSFULLY: "Abhebungsanfrage erfolgreich gesendet",
  ERROR_ON_SERVER: "Fehler auf dem Server",
  RECORD_NOT_FOUND: "Datensatz nicht gefunden!",
  GENERATED_SUCCESSFULLY: "Erfolgreich generiert",
  STAKING_CURRENCY_NOT_EXISTS: "Staking-Währung existiert nicht",
  INVESTMENT_PLAN_ADDED_SUCCESSFULLY:
    "Investitionsplan erfolgreich hinzugefügt.",
  STAKING_CURRENCY_NOT_EXISTS: "Staking-Währung existiert nicht",
  INVESTMENT_PLAN_UPDATED_SUCCESSFULLY:
    "Investitionsplan erfolgreich aktualisiert",
  FETCH_SUCCESS: "Erfolgreich abrufen",
  MIN_AMOUNT_GREATER_THAN_OR_EQUAL:
    "Mindestbetrag muss größer oder gleich sein als ",
  AMOUNT_LESS_THAN_OR_EQUAL: "Betrag muss kleiner oder gleich sein als ",
  INVALID_STAKING: "Ungültige Staking",
  INVALID_CURRENCY: "Ungültige Währung",
  DEACTIVE: "Deaktiviert",
  NO_RECORD: "Kein Eintrag",
  INVALID_ASSET: "Ungültiges Asset",
  INSUFFICIENT_BALANCE:
    "Es sind nicht genügend Vermögenswerte auf Ihrem Konto.",
  INVESTMENT_ORDER_ADDED_SUCCESSFULLY:
    "Investitionsauftrag erfolgreich hinzugefügt.",
  KINDLY_CHOOSE_DURATION_DAYS: "Bitte wählen Sie die Dauer in Tagen aus",
  NO_RECORD: "Kein Eintrag vorhanden",
  ALREADY_CANCELLED: "Bereits storniert",
  INVALID_CURRENCY: "Ungültige Währung",
  RELEASED_SUCCESSFULLY: "Erfolgreich freigegeben",
  ALREADY_REDEMPTION_COMPLETED: "Die Rücknahme wurde bereits abgeschlossen",
  INSUFFICIENT_AMOUNT: "Unzureichender Betrag",
  ENTER_VALID_AMOUNT: "Geben Sie einen gültigen Betrag ein",
  EMAIL_ALREADY_VERIFIED:
    "Ihre E-Mail wurde bereits verifiziert, Sie können sich jetzt anmelden",
  ACCOUNT_DEACTIVATED_BY_ADMIN:
    "Ihr Konto wurde vom Administrator deaktiviert. Bitte kontaktieren Sie den Administrator.",
  EMAIL_VERIFIED_CAN_LOGIN:
    "Ihre E-Mail wurde verifiziert, Sie können sich jetzt anmelden",
  AMOUNT_ZERO: "Betrag kann nicht null sein",
  AMOUNT_NEGATIVE: "Betrag kann nicht negativ sein",
  AMOUNT_EXCEEDS_STAKED:
    "Betrag muss kleiner oder gleich {{stakedAmount}} sein",
  SUCCESS_COPY: "Erfolgreich in die Zwischenablage kopiert",
  INTEREST_HISTORY: "Zinsverlauf",
  SEE_MORE: "Mehr sehen",
  STRATEGY: "Strategie",
  PERIOD: "Zeitraum",
  MANAGEMENT_FEE: "Managementgebühr %",
  MINIMUM_INVESTMENT: "Mindestinvestition",
  AMOUNT: "Betrag",
  CLOSE: "Schließen",
  INVEST: "Investieren",
  MONTHLY: "Monatlich",
  PROCESS: "Prozess",
  UNLOCKED: "Entsperrt",
  LOCKED: "Gesperrt",
  DAYS: "Tage",
  LOGOUT_SUCCESS: "Erfolgreich abgemeldet",
  INVEST_DASHBOARD_ROI_PERCENT: "ROI-Prozentsatz",
  HOME: "Startseite",
  INVEST: "Investieren",
  WALLET: "Brieftasche",
  REFERRAL: "Empfehlung",
  DASHBOARD: "Dashboard",
  LOGOUT: "Ausloggen",
  THERE_ARE_NO_RECORDS_TO_DISPLAY: "Es gibt keine Einträge zum Anzeigen",
  TOTAL_REFERRAL_INCOME_EARNED: "Gesamte Empfehlungseinnahmen",
  TOTAL_REFERRAL_USERS_INVEST: "Gesamte empfohlene Benutzer investieren",
  TYPE: "Typ",
  TRX_ID: "Transaktions-ID",
  TO_ADDRESS: "Zieladresse",
  FROM_ADDRESS: "Quelladresse",
  CURRENCY: "Währung",
  FEE: "Gebühr",
};
