import React, { useState, useEffect } from 'react';
import DataTable from "react-data-table-component";

// import api
import { getUsrRefEarnList } from '../../api/referralAction';
import { useTranslation } from "react-i18next";

// import lib
import { dateTimeFormat } from '../../lib/dateTimeHelper';
import { toFixedDown } from '../../lib/roundOf';

// Investment History Table Data
const columns = [
    {
        name: "Reward",
        selector: (row) => row.amount,
        cell: (record) => `${toFixedDown(record.amount, 4)} ${record.rewardCurrency}`
    },

    {
        name: "User Code",
        selector: (row) => row.userCode,
        cell: (record) => `ID ${record.userCode}`
    },
    {
        name: "Reward Currency",
        selector: (row) => row.rewardCurrency,
    },
    {
        name: "Reward Level",
        selector: (row) => row.rewardLevel,
        cell: (record) => `Level ${record.rewardLevel}`
    },
    {
        name: "Reward Percent",
        selector: (row) => row.rewardPercentage,
        cell: (record) => `Level ${record.rewardPercentage}`
    },
    {
        name: "Created At",
        selector: (row) => row.createdAt,
        cell: (record) => `${dateTimeFormat(record.createdAt)}`
    },
];

const customStyles = {
    headCells: {
        style: {
            fontFamily: 'Arial, sans-serif',
            fontSize: '15px', // Change font size
            fontWeight: '600', // Change font size
        },
    },
    cells: {
        style: {
            fontFamily: 'Arial, sans-serif',
            fontSize: '15px', // Change font size
        },
    },
    pagination: {
        style: {
            fontFamily: 'Arial, sans-serif',
            fontSize: '15px', // Change font size
        },
    },
  };

const ReferralHistory = () => {
  const { t, i18n } = useTranslation();

    // state
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [pageDoc, setPageDoc] = useState({ 'page': 1, 'limit': 10 })

    const { page, limit } = pageDoc;

    const getRefUsrHistory = async () => {
        try {
            const { status, result } = await getUsrRefEarnList({ page, limit });
            if (status === 'success') {
                setData(result.data)
                setCount(result.count)
            }
        } catch (err) { }
    }

    useEffect(() => {
        getRefUsrHistory()
    }, [page, limit]);

    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="chart_box">
              <div className="inner_subtitle">
                <h2>{t("REFERRAL_HISTORY_LABEL")}</h2>
              </div>
              <div className="table-responsive primary_table_div">
                <DataTable
                  className="table primary_table"
                  columns={columns}
                  data={data}
                  pagination
                  paginationServer
                  paginationTotalRows={count}
                  onChangePage={(page) =>
                    setPageDoc({ ...pageDoc, ...{ page: page } })
                  }
                  onChangeRowsPerPage={(limit) =>
                    setPageDoc({ ...pageDoc, ...{ limit: limit } })
                  }
                  customStyles={customStyles}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default ReferralHistory;