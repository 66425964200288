export default {CUSTOM_SOLUTION: "Maatwerkoplossing",
  PRIVACY_SECTION_PRIVACY: "Privacy",
  LOGIN_LINK_LABEL: "Inloggen",
  REGISTER_LINK_LABEL: "Registreren",
  CLICK_ME_LABEL: "Klik op mij!",
  LOGIN_EMAIL_PLACEHOLDER: "Voer uw e-mailadres in",
  EMPOWER_FINANCIAL_FUTURE: "Versterk jouw financiële toekomst.",
  HOME_DESCRIPTION:
    "Dompel jezelf onder in de wereld van cryptocurrency met deskundige begeleiding die is ontworpen om je investeringsreis te versnellen. Begin vandaag met investeren in crypto en zie je portfolio groeien. Mantis Network biedt begeleiding om de essenties van een succesvolle crypto-investeerder te begrijpen.",
  GET_STARTED: "Begin",
  DASHBOARD: "Dashboard",
  ALL_PARTICIPANTS: "Alle Deelnemers",
  ASSETS_UNDER_MANAGEMENT: "Beheerde Activastromen",
  WHY_INVEST_WITH_US: "Waarom investeren met ons",
  SECURE_AND_RELIABLE: "Veilig en Betrouwbaar",
  PLATFORM_SECURITY_BRIEF_EXPLANATION:
    "Ons platform geeft prioriteit aan beveiliging. Uw activa zijn beschermd door geavanceerde technologie en toonaangevende beveiligingsprotocollen in de branche.",
  EARN_REWARDS_EFFORTLESSLY: "Verdien Beloningen Zonder Moeite",
  INVESTMENT_DESCRIPTION:
    "Investeren bij ons betekent dat je moeiteloos beloningen verdient in de vorm van extra cryptocurrencies. Geen complexe handelsstrategieën of technische kennis vereist.",
  DECENTRALIZATION_MATTERS: "Decentralisatie is Belangrijk",
  JOIN_THE_MOVEMENT:
    "Sluit je aan bij de beweging naar een gedecentraliseerde toekomst. Door je crypto te investeren, draag je actief bij aan de kracht en stabiliteit van het blockchain-netwerk.",
  USER_FRIENDLY_INTERFACE: "Gebruiksvriendelijke Interface",
  INTUITIVE_PLATFORM:
    "Ons intuïtieve platform maakt investeren eenvoudig voor zowel beginners als ervaren investeerders. Geen verborgen kosten of ingewikkelde processen - gewoon een naadloze ervaring.",
  HOW_IT_WORKS: "Hoe Het Werkt",
  MANTIS_NETWORK_REGISTRATION:
    "Met Mantis Network hoef je je alleen maar te registreren en een account aan te maken zonder enige kennis van blockchain of crypto.",
  SIMPLE_PATH_WITH_MANTIS_NETWORK: "Jouw Eenvoudige Pad met Mantis Network",
  CREATE_YOUR_ACCOUNT: "Maak Jouw Account",
  SIGN_UP_WITH_MANTIS_NETWORK:
    "Meld je eenvoudig aan bij Mantis Network, zonder voorafgaande ervaring met blockchain of crypto wallet. Ons gebruiksvriendelijke platform is ontworpen voor iedereen.",
  EXPLORE_INVESTMENT_OPTIONS: "Verken Investeringsopties",
  DISCOVER_INVESTMENT_OPPORTUNITIES:
    "Ontdek verschillende investeringsmogelijkheden, waaronder Bitcoin-mining en Ethereum-staking, op maat gemaakte Mantis Strategies die aansluiten bij jouw financiële doelen.",
  BEGIN_INVESTING: "Begin met Investeren",
  START_INVESTMENT_JOURNEY:
    "Met onze stapsgewijze begeleiding begin je aan je investeringsreis in lucratieve activa zoals Bitcoin, Ethereum, ALT-munten en diverse vroege projecten.",
  WATCH_PORTFOLIO_GROW: "Zie Jouw Portfolio Groeien",
  TRACK_INVESTMENTS:
    "Volg moeiteloos je investeringen op Mantis Network en zie je portfolio groeien terwijl je dieper in de wereld van crypto-investeringen duikt.",
  ASSET_ALLOCATION: "Activatoewijzing",
  FOCUS_ON_INCOME_GENERATION:
    "Richt je op inkomstengeneratie door dagelijks BTC (Bitcoin) beloningen te genereren in je digitale portemonnee en ETH (Ethereum) staking beloningen.",
  BTC_MINING_ALLOCATOR_ALLOCATION:
    "40% toewijzing aan een BTC-mining-allocator",
  BTC_ALLOCATION: "30% toewijzing aan BTC",
  ETH_ALLOCATION: "20% toewijzing aan ETH (Ethereum)",
  MANTIS_STRATEGIES_ALLOCATION:
    "10% toewijzing aan Mantis Strategies die Alpha zoeken",
  INVESTOR_PORTFOLIO_DESCRIPTION:
    "Dit portfolio is geschikt voor investeerders die zich willen concentreren op het opbouwen van inkomen en het samengesteld rendement, met een neiging naar blootstelling aan de BTC-prijs.",
  INVESTOR_PORTFOLIO_BLURB: "Gemengde focus op Inkomstengeneratie en Groei",
  GROWTH_FOCUS_BLURB:
    "Richt je op het genereren van grote groei met een grotere toewijzing aan onze op Alpha gerichte Mantis Strategies",
  BTC_MINING_ALLOCATOR_ALLOCATION:
    "30% toewijzing aan een BTC-mining-allocator",
  BTC_ALLOCATION: "20% toewijzing aan BTC",
  ETH_ALLOCATION: "20% toewijzing aan ETH (Ethereum)",
  MANTIS_STRATEGIES_ALLOCATION:
    "30% toewijzing aan Mantis Strategies die Alpha zoeken",
  INVESTOR_PORTFOLIO_DESCRIPTION:
    "Dit portfolio is geschikt voor investeerders die zich willen concentreren op het opbouwen van inkomen en het samengesteld rendement, met een neiging naar blootstelling aan de BTC-prijs, en die ook zeer hoge rendementen zoeken in de Decentralized Finance en Alternative coin-markt.",
  INVEST_TITLE: "Investeren",
  BALANCED_TITLE: "Evenwichtig",
  HIGH_YIELD_TITLE: "Hoog Rendement",
  GROWTH_TITLE: "Groei",
  ROI_CALCULATOR_MAIN_TITLE: "ROI Calculator",
  ROI_CALCULATOR_INVESTMENTS_LABEL: "Investeringen",
  ROI_CALCULATOR_INVESTMENT_AMOUNT_LABEL: "Investering Bedrag",
  ROI_CALCULATOR_APY_LABEL: "APY In Percentage (%)",
  ROI_CALCULATOR_MONTHS_LABEL: "Aantal Maanden",
  ROI_CALCULATOR_INVEST_BUTTON: "Investeer",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_1: "1 Maand",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_3: "3 Maanden",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_6: "6 Maanden",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_9: "9 Maanden",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_12: "12 Maanden",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_24: "24 Maanden",
  ROI_CALCULATOR_SELECT_MONTH_OPTION_48: "48 Maanden",
  ROI_CALCULATOR_INTEREST_LABEL: "Totale Rente:",
  ROI_CALCULATOR_FUTURE_VALUE_LABEL: "Toekomstige Waarde:",
  NEWS_COMPONENT_MAIN_TITLE: "Ons nieuws & Updates",
  NEWS_COMPONENT_VIEW_MORE_BUTTON: "Bekijk Meer",
  FAQ_COMPONENT_MAIN_TITLE: "Veelgestelde Vragen",
  FAQ_COMPONENT_VIEW_MORE_BUTTON: "Bekijk Meer",
  FOOTER_INNOVATIVE_PLATFORM:
    "Mantis Network is een innovatief platform waarmee traditionele investeerders eenvoudig blootstelling kunnen krijgen aan cryptocurrency-investeringen.",
  FOOTER_FOLLOW_US: "Volg Ons",
  FOOTER_COPYRIGHT: "Auteursrecht ©",
  FOOTER_ALL_RIGHTS_RESERVED: "Alle Rechten Voorbehouden.",
  FOOTER_ADDRESS: "Adres",
  FOOTER_CONTACT_US: "Neem Contact Met Ons Op",
  FOOTER_TERMS_OF_SERVICE: "Gebruiksvoorwaarden",
  FOOTER_PRIVACY_POLICY: "Privacybeleid",
  INVEST_TITLE: "Investeer",
  INVEST_TABLE_HEADER_ASSETS: "Activa",
  INVEST_TABLE_HEADER_WEIGHTS: "Gewichten",
  INVEST_ALLOCATION_BTC: "BTC",
  INVEST_ALLOCATION_ETH: "ETH",
  INVEST_ALLOCATION_MINING_POOL: "BTC Mijnbouwzwembad",
  INVEST_ALLOCATION_MANTIS_STRATEGIES: "Mantis Strategieën",
  INVEST_ALLOCATION_TOTAL: "Totale Toewijzing",
  INVEST_MINIMUM_INVESTMENT: "Minimale Investering",
  INVEST_PROJECTED_ROI: "Geschatte ROI (%)",
  INVEST_DETAILS: "Details",
  INVEST_INVEST_BUTTON: "Investeer",
  INVEST_INVESTMENT_DETAILS: "Investering Details",
  INVEST_MONTH: "Maand",
  INVEST_INTEREST_RATE: "Rentevoet",
  INVEST_MONTHS: "Maanden",
  INVEST_AMOUNT: "Bedrag",
  INVEST_INVEST_MODAL_TITLE: "Investeer Order",
  INVEST_INVEST_MODAL_DESCRIPTION:
    "Controleer uw investeringsgegevens en ga verder met het plaatsen van de order.",
  INVEST_INVEST_MODAL_AMOUNT_LABEL: "Bedrag",
  INVEST_INVEST_MODAL_PLACE_ORDER: "Order Plaatsen",
  INVEST_INVEST_MODAL_CLOSE: "Sluiten",
  INVEST_RESPONSE_MODAL_TITLE_SUCCESS: "Succes",
  INVEST_RESPONSE_MODAL_TITLE_ERROR: "Fout",
  INVEST_RESPONSE_MODAL_CLOSE: "Sluiten",
  INVEST_DASHBOARD_TOTAL_LOCKED_VALUE: "Totale Geblokkeerde Waarde",
  INVEST_DASHBOARD_CAPITAL_GROWTH: "Kapitaalgroei",
  INVEST_DASHBOARD_YOUR_EARNINGS: "Uw Verdiensten",
  INVEST_DASHBOARD_AVAILABLE_BALANCE: "Beschikbaar Saldo",
  INVEST_DASHBOARD_WALLET_LINK: "Portemonnee",
  INVEST_DASHBOARD_INVEST_LINK: "Investeer",
  INVESTMENT_DETAILS_INVESTMENT_DETAILS_TITLE: "Investeringsdetails",
  INVESTMENT_DETAILS_STRATEGY_LABEL: "Strategie",
  INVESTMENT_DETAILS_DURATION_LABEL: "Duur",
  INVESTMENT_DETAILS_ALL_OPTION: "Alle",
  INVESTMENT_DETAILS_LOW_OPTION: "Laag",
  INVESTMENT_DETAILS_HIGH_OPTION: "Hoog",
  INVESTMENT_DETAILS_ID_LABEL: "ID",
  INVESTMENT_DETAILS_TOTAL_INVESTMENT_PERIOD_LABEL:
    "Totale Investeringsperiode",
  INVESTMENT_DETAILS_REWARDS_EARNED_LABEL: "Verdiende Beloningen",
  INVESTMENT_DETAILS_REMAINING_DAYS_LABEL: "Resterende Dagen",
  INVESTMENT_DETAILS_CAPITAL_GROWTH_LABEL: "Kapitaalgroei",
  INVESTMENT_DETAILS_VIEW_BUTTON_LABEL: "Bekijk",
  INVESTMENT_DETAILS_INVESTED_AT_LABEL: "Geïnvesteerd op",

  RECENT_ACTIVITY_RECENT_ACTIVITY_TITLE: "Recente Activiteit",
  RECENT_ACTIVITY_SEE_MORE_LINK: "Bekijk meer",
  RECENT_ACTIVITY_TRANSFER_TYPE_LABEL: "Overdracht via USD",
  RECENT_ACTIVITY_DEPOSIT_TYPE_LABEL: "Storting via USD",
  RECENT_ACTIVITY_WITHDRAW_TYPE_LABEL: "Opname via USD",
  RECENT_ACTIVITY_DATE_LABEL: "Datum",
  RECENT_ACTIVITY_STATUS_LABEL: "Status",
  RECENT_ACTIVITY_REJECTED_STATUS: "Afgewezen",
  RECENT_ACTIVITY_GREEN_TEXT: "groen_txt",
  RECENT_ACTIVITY_RED_TEXT: "rood_txt",
  FORGOT_PASSWORD_TITLE: "Wachtwoord Vergeten",
  FORGOT_PASSWORD_RESET_INFO:
    "De link om het wachtwoord opnieuw in te stellen is naar uw geregistreerde e-mailadres gestuurd",
  FORGOT_PASSWORD_EMAIL_LABEL: "E-mailadres",
  FORGOT_PASSWORD_SUBMIT_BUTTON: "Verzenden",
  FORGOT_PASSWORD_GO_TO_LOGIN: "Ga naar Inloggen",

  LOGIN_WELCOME_TITLE: "Welkom bij",
  LOGIN_MANTIS_NETWORK: "Mantis Network",
  LOGIN_SIGN_IN: "Log in om door te gaan!",
  LOGIN_EMAIL_LABEL: "E-mailadres",
  LOGIN_PASSWORD_LABEL: "Wachtwoord",
  LOGIN_REMEMBER_ME: "Onthoud mij",
  LOGIN_FORGOT_PASSWORD: "Wachtwoord Vergeten?",
  LOGIN_LOGIN_BUTTON: "Inloggen",
  LOGIN_DONT_HAVE_ACCOUNT: "Heeft u geen account?",
  LOGIN_REGISTER_HERE: "Registreer Hier",
  LOGIN_OTP_VALID_UP_TO: "OTP Geldig Tot",
  LOGIN_YOUR_OTP_SENT:
    "Uw Eenmalige Toegangscode (OTP) is naar uw geregistreerde e-mailadres gestuurd",
  LOGIN_ENTER_OTP: "Voer OTP in",
  LOGIN_SUBMIT_BUTTON: "Verzenden",
  LOGIN_RESEND_OTP: "Stuur OTP opnieuw",

  NEWS_EVENTS_INNER_HEAD_TITLE: "Nieuws en Updates",

  PRIVACY_INNER_HEAD_TITLE: "Privacybeleid - Mantis Network",
  PRIVACY_PARAGRAPH_1:
    "De bescherming van gegevens en vertrouwelijkheid van uw persoonlijke gegevens is een belangrijke zorg van Mantis Network. Het gebruik van onze website is afhankelijk van het verzamelen van persoonlijke gegevens, die vereist zijn bij de registratie en voor uw gebruikerstoegang. Mantis Network voldoet strikt aan alle wettelijke voorschriften en verwerkt uw zeer gevoelige gegevens conscientieus en verantwoord. We willen echter benadrukken dat absolute bescherming van de gegevens tijdens de overdracht op het internet niet mogelijk is en als gevolg daarvan kan toegang door derden op elk moment niet volledig worden vermeden. Desalniettemin kunt u ervan uitgaan dat we alle noodzakelijke technische en organisatorische beveiligingsmaatregelen nemen om uw persoonlijke gegevens te beschermen tegen verlies en misbruik",
  PRIVACY_SECTION_COOKIES: "Cookies",
  PRIVACY_PARAGRAPH_2:
    "De website maakt gebruik van PHP-sessiecookies. Cookies beschadigen uw computer niet en bevatten geen virussen. Cookies zijn een mechanisme om informatie op te slaan om terugkerende gebruikers te identificeren of hun pad bij het gebruik van de website te volgen. Cookies worden gebruikt om onze website gebruiksvriendelijker, comfortabeler en veiliger te maken. Het uitschakelen van cookies kan de functionaliteit van deze website beperken",
  PRIVACY_SECTION_LOG_FILES: "Server LOG-bestanden",
  PRIVACY_PARAGRAPH_3:
    "Onze server verzamelt en slaat automatisch informatie op in zogenaamde serverlogbestanden, die uw browser automatisch naar ons verzendt. De informatie bevat geen persoonlijke gegevens. Een koppeling van deze gegevens met uw persoonlijke gegevens is niet de bedoeling. We behouden ons het recht voor om deze gegevens achteraf te controleren als we op de hoogte worden gebracht van specifieke aanwijzingen voor onrechtmatig gebruik",
  PRIVACY_SECTION_SSL: "SSL-codering",
  PRIVACY_PARAGRAPH_4:
    "Om veiligheidsredenen maakt deze site gebruik van SSL-codering voor de overdracht van gegevens. Zolang SSL-codering is ingeschakeld, kunnen gegevens tijdens de overdracht niet door derden worden gelezen",
  PRIVACY_SECTION_CONTACT_FORM: "Contactformulier",
  PRIVACY_PARAGRAPH_5:
    "Als u ons een bericht stuurt via het 'CONTACT'-formulier, wordt u ten minste gevraagd naar uw naam en uw e-mailadres. Deze gegevens worden samen met het bericht verzonden en opgeslagen voor verdere verwerking. De informatie 'telefoon' en 'adres' zijn vrijwillig. We zullen al deze informatie niet delen zonder uw toestemming",
  PRIVACY_SECTION_REGISTRATION: "Registratie",
  PRIVACY_PARAGRAPH_6:
    "Na registratie slaan we uw wachtwoord anoniem op, alleen uw e-mailadres is voor ons zichtbaar. Bijgevolg kunnen we alleen wachtwoorden resetten als ze vergeten zijn. U heeft uw e-mailadres en wachtwoord nodig om in te loggen op onze website. U bent persoonlijk verantwoordelijk voor de effectieve geheimhouding van uw wachtwoorden. Geef de inloggegevens aan niemand anders",
  PRIVACY_SECTION_CONSENT: "Verklaring van Toestemming",
  PRIVACY_PARAGRAPH_7:
    "Met deelname en gebruik van onze diensten stemt u ook in met de verwerking van uw persoonlijke gegevens. De diensten kunnen niet worden verleend zonder de verwerking van uw persoonlijke gegevens",
  PRIVACY_SECTION_KYC: "KYC Compliance",
  PRIVACY_PARAGRAPH_8:
    "Met betrekking tot EU- en VS-sancties, evenals de Wet ter voorkoming van witwassen, is Mantis Network verplicht om, op basis van uw zelfopenbaring, zo veel mogelijk een KYC (Ken Uw Klant) controle uit te voeren en te documenteren. Deze procedure vereist ook een bewijs van uw identiteit. Deze gegevens worden zonder uw voorafgaande toestemming niet aan derden bekendgemaakt, tenzij Mantis Network wettelijk verplicht is om deze te verstrekken. Natuurlijk zal Mantis Network zijn klanten onmiddellijk informeren als een controle tot afwijkingen leidt en zij hebben het recht om commentaar te geven",
  PRIVACY_SECTION_THIRD_PARTY: "Diensten van Derden",
  PRIVACY_PARAGRAPH_9:
    "Mantis Network schakelt andere bedrijven en individuen in voor prestaties die verband houden met onze diensten of zakelijke administratieve doeleinden. Deze dienstverleners van derden hebben alleen toegang zolang en in de mate dat ze absoluut nodig hebben voor het doel of waarvoor ze wettelijk verplicht zijn",
  PRIVACY_SECTION_INFORMATION: "Recht op Informatie",
  PRIVACY_PARAGRAPH_10:
    "U heeft te allen tijde het recht om informatie te verkrijgen over uw persoonlijke gegevens, evenals het doel en het type gegevensverwerking van uw gegevens. Als u vragen heeft over persoonlijke gegevens, kunt u altijd contact met ons opnemen op het adres dat wordt vermeld bij „over ons“",
  PRIVACY_SECTION_TELEGRAM: "Privacybeleid voor het Gebruik van Telegram",
  PRIVACY_PARAGRAPH_11:
    "Op onze website zijn functies van de service Telegram geïntegreerd. Deze functies worden aangeboden door Telegram Messenger LLP. 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ, Verenigd Koninkrijk. Door de link naar Telegram te gebruiken, kunt u deelnemen aan het 'MANTIS NETWORK info kanaal' met uw telegramaccount. Meer informatie is te vinden in het privacybeleid van Telegram onder uw account: Telegram FAQ, F:, Privacybeleid",
  PRIVACY_SECTION_FACEBOOK: "Privacybeleid voor het Gebruik van Facebook",
  PRIVACY_PARAGRAPH_12:
    "Op onze website zijn functies van de service Facebook geïntegreerd. Deze functies worden aangeboden door Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ierland. Door de link naar Facebook te gebruiken, kunt u ons account 'Mantis Network Limited' bezoeken. Meer informatie is te vinden in het privacybeleid van Facebook; https://www.facebook.com/privacy/explanation",
  PROFILE_PROFILE_TITLE: "Profiel",
  PROFILE_USERNAME_LABEL: "Gebruikersnaam",
  PROFILE_EMAIL_LABEL: "E-mail",
  PROFILE_PHONE_NUMBER_LABEL: "Telefoonnummer",
  PROFILE_CHANGE_USERNAME_LABEL: "Gebruikersnaam wijzigen",
  PROFILE_CHANGE_PASSWORD_LABEL: "Wachtwoord wijzigen",
  PROFILE_PASSWORD_DESCRIPTION:
    "Dit wachtwoord wordt gebruikt voor uw aanmeldingsreferenties.",
  PROFILE_CHANGE_BUTTON: "Veranderen",

  CHANGE_PROFILE_CHANGE_USERNAME_TITLE: "Gebruikersnaam wijzigen",
  CHANGE_PROFILE_ENTER_NEW_USERNAME: "Voer nieuwe gebruikersnaam in",
  CHANGE_PROFILE_CLOSE_BUTTON: "Sluiten",
  CHANGE_PROFILE_SAVE_BUTTON: "Opslaan",

  CHANGE_PASSWORD_CHANGE_PASSWORD_TITLE: "Wachtwoord wijzigen",
  CHANGE_PASSWORD_ENTER_OTP: "Voer OTP in",
  CHANGE_PASSWORD_ENTER_OLD_PASSWORD: "Voer oud wachtwoord in",
  CHANGE_PASSWORD_ENTER_NEW_PASSWORD: "Voer nieuw wachtwoord in",
  CHANGE_PASSWORD_ENTER_CONFIRM_PASSWORD: "Bevestig wachtwoord invoeren",
  CHANGE_PASSWORD_CLOSE_BUTTON: "Sluiten",
  CHANGE_PASSWORD_SEND_BUTTON: "Verzenden",
  CHANGE_PASSWORD_VERIFY_BUTTON: "Controleren",
  CHANGE_PASSWORD_SUBMIT_BUTTON: "Verzenden",

  WALLET_DETAILS_TITLE: "Portemonnee",
  WALLET_DETAILS_TOTAL_BALANCE: "Totaal saldo",
  WALLET_DETAILS_INVEST_BUTTON: "Investeer",

  DEPOSIT_TITLE: "Storting",
  DEPOSIT_SELECT_COIN: "Selecteer Munt",
  DEPOSIT_SELECT_NETWORK: "Selecteer Netwerk",
  DEPOSIT_ADDRESS_LABEL: "Adres",
  DEPOSIT_COPY_ICON_ALT: "Kopieer",
  DEPOSIT_GENERATE_ADDRESS_BUTTON: "Genereer Adres",
  DEPOSIT_QR_IMAGE_ALT: "Wazige Afbeelding",
  DEPOSIT_DEPOSIT_NOTES: "Stortingsinstructies",
  DEPOSIT_SEND_ONLY: "Stuur alleen {currencySymbol} naar dit stortingsadres.",
  DEPOSIT_MINIMUM_DEPOSIT:
    "Minimale storting: {minimumDeposit} {currencySymbol}",
  DEPOSIT_SENDING_COIN:
    "Het sturen van een munt of token anders dan {currencySymbol} naar dit adres kan resulteren in het verlies van uw storting.",
  WITHDRAW_TITLE: "Opnemen",
  WITHDRAW_OTP_VALID_UP_TO: "OTP Geldig Tot",
  WITHDRAW_OTP_LABEL: "OTP",
  WITHDRAW_ENTER_OTP: "Voer OTP in",
  WITHDRAW_SUBMIT: "Verzenden",
  WITHDRAW_RESEND_OTP: "OTP Opnieuw Verzenden",
  WITHDRAW_SELECT_COIN: "Selecteer Munt",
  WITHDRAW_SELECT: "Selecteer",
  WITHDRAW_AMOUNT: "Bedrag",
  WITHDRAW_ENTER_AMOUNT: "Voer Bedrag in",
  WITHDRAW_SELECT_NETWORK: "Selecteer Netwerk",
  WITHDRAW_CONVERSION_RATE: "Wisselkoers",
  WITHDRAW_ENTER_ADDRESS: "Voer Adres in",
  WITHDRAW_ADDRESS: "Adres",
  WITHDRAW_WITHDRAW_NOW: "Nu Opnemen",
  WITHDRAW_WITHDRAW_NOTES: "Opname Notities",
  WITHDRAW_SEND_ONLY: "Stuur alleen {{currencySymbol}} naar dit opname-adres.",
  WITHDRAW_MINIMUM_WITHDRAW:
    "Minimale Opname: {{minimumWithdraw}} {{currencySymbol}}",
  WITHDRAW_MAXIMUM_WITHDRAW:
    "Maximale Opname: {{maximumWithdraw}} {{currencySymbol}}",
  WITHDRAW_WITHDRAWAL_FEE: "Opnamekosten: {{withdrawFee}} {{currencySymbol}}",
  WITHDRAW_SENDING_COIN:
    "Het verzenden van een munt of token anders dan {{currencySymbol}} naar dit adres kan leiden tot verlies van uw opname.",

  TRX_HISTORY_TITLE: "Transactiegeschiedenis",
  TERMS_TITLE: "Gebruiksvoorwaarden",
  TERMS_GENERAL: "1. Algemeen",
  TERMS_GENERAL_CONTENT_A:
    "a. Deze Algemene Voorwaarden (hierna „AV“) zijn van toepassing op het gebruik van Mantis Network hostingdiensten (hierna “Dienst”) van de website http://Mantis.Network (hierna “Website”). Het gebruik van de terminologie hierin of andere woorden in het enkelvoud, meervoud, hoofdletters en/of hij/zij of zij/hun worden geïnterpreteerd als uitwisselbaar en daarom als verwijzend naar hetzelfde.",
  TERMS_GENERAL_CONTENT_B:
    "b. Iedere individu, entiteit of andere rechtspersoon (hierna “Gebruiker”) die toegang heeft tot de Website en de Diensten zal gebruiken, moet de AV eerst lezen, accepteren en bevestigen en instemmen gebonden te zijn aan de bepalingen van de AV voordat hij lid wordt. Elke feitelijke deelname aan de Diensten zal dergelijke acceptatie vormen. Als de Gebruiker niet akkoord gaat met en zich niet houdt aan deze AV, is het hem niet toegestaan de Diensten te gebruiken.",
  TERMS_GENERAL_CONTENT_C:
    "c. Elke Gebruiker van de Diensten bevestigt bij het abonneren op de Diensten onbeperkt sui juris (contractuele bekwaamheid) te zijn en ten minste achttien (18) jaar oud. Bovendien is elke Gebruiker zich bewust van alle mogelijke risico's die verbonden zijn aan het gebruik van de Diensten, blockchain-technologie en cryptovaluta.",
  TERMS_EXCLUSIONS_LIMITATIONS_TITLE: "2. Uitsluitingen en Beperkingen",
  TERMS_EXCLUSIONS_LIMITATIONS_CONTENT_A:
    "a. De informatie op de website wordt verstrekt op basis van 'zoals het is' in de grootst mogelijke mate die door de wet is toegestaan. De service sluit alle verklaringen en garanties uit met betrekking tot de website en de inhoud ervan, evenals inhoud die kan worden verstrekt door gelieerde ondernemingen of andere derden, inclusief eventuele onnauwkeurigheden of weglatingen met betrekking tot de website en/of de services, evenals de bijbehorende presentaties, demonstraties en beschrijvingen.",
  TERMS_EXCLUSIONS_LIMITATIONS_CONTENT_B:
    "b. De uptime van de service of website wordt niet gegarandeerd; onderhoud en downtime kunnen van tijd tot tijd nodig zijn. De eigenaar is niet verantwoordelijk voor de continue en foutloze werking en de beveiliging van de cryptovalutanetwerken. Desalniettemin zal de eigenaar zijn uiterste best doen om de service in goede staat te leveren en eventuele ergere gevolgen te vermijden.",
  TERMS_LIMITATION_LIABILITIES_TITLE: "3. Beperking van Aansprakelijkheid",
  TERMS_LIMITATION_LIABILITIES_CONTENT_A:
    "a. Mantis Network Ltd., de wettelijke vertegenwoordigers en aandeelhouders (hierna gezamenlijk de 'eigenaar') zijn niet aansprakelijk voor directe of indirecte, gevolg- en incidentele schade die verband houdt met of veroorzaakt wordt door de website en/of de services, ongeacht of de gevolgen al dan niet voorzienbaar waren en zich kunnen voordoen in een normaal geval zoals dingen gebeuren.",
  TERMS_LIMITATION_LIABILITIES_CONTENT_B:
    "b. Met name de eigenaar is niet aansprakelijk voor schade zoals, maar niet beperkt tot, schade aan de computerhardware of -software van de gebruiker, systemen en programmering of verlies van gegevens, evenals economisch verlies, verlies van bedrijf of winstderving.",
  TERMS_LIMITATION_LIABILITIES_CONTENT_C:
    "c. De gebruiker bevestigt zich bewust te zijn van alle risico's, inclusief de mogelijkheid van economisch verlies of verlies van verwachte winsten. De eigenaar zal geen onomkeerbaar verlies vergoeden.",
  TERMS_PAYOUTS_TITLE: "4. Uitbetalingen",
  TERMS_PAYOUTS_CONTENT_A:
    "a. Mantis Network zal beloningen evenredig uitbetalen aan het door de gebruiker geïnvesteerde bedrag. Alle transacties worden verwerkt in de valuta USDT.",
  TERMS_PAYOUTS_CONTENT_B:
    "b. De uitbetalingen worden ALLEEN overgemaakt naar de account die de gebruiker heeft vermeld op het investeringsformulier (dashboard). De uitbetalingen worden na elke vier (4) weken regelmatig bijgeschreven.",
  TERMS_PAYOUTS_CONTENT_C:
    "c. De eigenaar brengt voor de services een toetredingsvergoeding in rekening van 2% over alle stortingen en een vergoeding van 25% over alle beloningen van Mantis Network.",
  TERMS_PAYOUTS_CONTENT_D:
    "d. Eventuele beloningen die zijn opgebouwd door een aangewezen cryptovaluta-account worden alleen betaald naar dat specifieke adres. Verschillende wallet-balansen kunnen niet worden samengevoegd.",
  TERMS_PAYOUTS_CONTENT_E:
    "e. De eigenaar is niet aansprakelijk voor wijzigingen in de waarde van cryptovaluta. Het rendement op investering (ROI) wordt berekend op basis van de daadwerkelijke beloningen voor elk blok van de laatste 24 uur. De eigenaar kan niet garanderen dat een cryptovaluta een constante waarde behoudt en kan daarom de ROI niet garanderen. Totaal verlies is op elk moment mogelijk en elk risico wordt gedragen door elke gebruiker.",
  TERMS_PAYOUTS_CONTENT_F:
    "f. De portefeuillewaarde kan eenmaal per kwartaal kosteloos worden opgevraagd, maximaal 4 weken voor het einde van de houdperiode, voor berekening via de ondersteuning. Elke verdere berekening wordt in rekening gebracht met $25 vanwege de hoge inspanning, die vooraf moet worden betaald in USDT. De portefeuillewaarde heeft telkens een geldigheid van 7 dagen. Om operationele redenen en vanwege de hoge en terugkerende verzoeken om de portefeuillewaarde kan het zijn dat een poolwaardeberekening slechts eenmaal per kwartaal wordt berekend, maximaal 4 weken voor het einde van de houdperiode en wordt doorgegeven.",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_TITLE:
    "5. Wettelijke vereisten en sancties",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_A:
    "a. De gebruiker verklaart door zijn instemming met deze TOS dat hij niet is getroffen door enige sanctie of dat hij is vermeld door enige bevoegde toezichthoudende autoriteiten. Hij moet wijzigingen onmiddellijk melden. De eigenaar zal zijn status regelmatig controleren. Een positief screeningresultaat leidt tot onmiddellijke stopzetting van de services.",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_B:
    "b. Bovendien bevestigt de gebruiker met zijn instemming met de TOS dat hij op eigen kosten handelt.",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_C:
    "c. De eigenaar is gerechtigd om bewijs van de identiteit van de gebruiker te eisen. In het geval van natuurlijke personen zou dit meestal een officieel document van een autoriteit zijn (bijv. identiteitskaart), in het geval van een rechtspersoon toezichthoudende autoriteiten door een actuele uittreksel uit het handelsregister.",
  TERMS_LEGAL_REQUIREMENTS_SANCTIONS_CONTENT_D:
    "d. In het geval van onjuiste of onvolledige informatie, evenals fraude of bedrieglijke voorstelling van zaken, zal de gebruiker de eigenaar vrijwaren van alle juridische gevolgen en financiële schade. Bovendien zal de eigenaar de gebruiker onmiddellijk schorsen van het gebruik van de website en services. De eigenaar behoudt zich het recht voor om juridische stappen te ondernemen.",
  TERMS_WAIVER_TITLE: "6. Afstand",
  TERMS_WAIVER_CONTENT:
    "Geen afstand van enige bepaling van de TOS wordt geacht afstand te doen van enige andere bepaling, noch zal een dergelijke afstand een voortdurende afstand vormen, tenzij uitdrukkelijk anders schriftelijk is overeengekomen en ondertekend door de partij die daardoor gebonden is.",
  TERMS_FORCE_MAJEURE_TITLE: "7. Overmacht",
  TERMS_FORCE_MAJEURE_CONTENT:
    "De Eigenaar is niet aansprakelijk in enige gebeurtenis of omstandigheid die buiten de controle van de Eigenaar ligt, inclusief maar niet beperkt tot daden van God, rellen, oorlogen, overstromingen, branden, explosies, stakingen en andere soortgelijke gebeurtenissen, die de levering van reeds toegezegde Diensten zullen voorkomen, vertragen, verstoren of falen.",
  TERMS_TERMINATION_TITLE: "8. Beëindiging",
  TERMS_TERMINATION_CONTENT_A:
    "De toestemming van de Gebruiker voor de TOS omvat een investering in het Mantis Network. De minimale vergrendelingsperiode van de portefeuille is geldig voor een periode van (180) honderdtachtig kalenderdagen en wordt automatisch verlengd als de gebruiker de TOS niet beëindigt aan het einde van de minimale vergrendelingsperiode. De Gebruiker kan zijn TOS en het gebruik van de Diensten beëindigen door een e-mail te sturen naar support@Mantis.Network. Het aandeel van de huidige Mantis Network-portefeuillewaarde zal worden uitbetaald. De Eigenaar heeft het recht op buitengewone beëindiging als er dwingende redenen zijn tegen een voortzetting van de zakelijke relatie met een Gebruiker. In het geval van een voortijdige buitengewone beëindiging van het contract wordt het aandeel van de huidige Mantis Network-portefeuillewaarde, verminderd met een verwerkingskosten van 25%, terugbetaald. Er is geen recht op onbetaalde of toekomstige uitbetalingen.",
  TERMS_SEVERABILITY_CLAUSE_TITLE: "9. Scheidingsclausule",
  TERMS_SEVERABILITY_CLAUSE_CONTENT:
    "Als een bepaling van de TOS ongeldig wordt, heeft de ongeldigheid geen invloed op andere bepalingen van de TOS die zonder de ongeldige bepaling effect kunnen hebben, en om dit te bereiken, zijn de bepalingen van de TOS te scheiden.",
  TERMS_GOVERNING_LAW_TITLE: "10. Toepasselijk recht",
  TERMS_GOVERNING_LAW_CONTENT:
    "De rechten en verplichtingen van de Eigenaar en Gebruiker en derden, indien van toepassing, krachtens de TOS, de Website en de Dienst worden beheerst door, en zullen worden uitgelegd in overeenstemming met de wetten van de Britse Maagdeneilanden. Elke geschil, controverse of claim die voortvloeit uit of verband houdt met de TOS, de Website en de Dienst zal uiteindelijk worden beslecht door arbitrage overeenkomstig de Arbitrageregels van de Internationale Kamer van Koophandel (Parijs, Frankrijk). De arbitrageprocedure zal worden uitgevoerd in Tortola, Britse Maagdeneilanden. De taal van de procedure zal Engels zijn.",

  RESET_PASSWORD_TITLE: "Wachtwoord opnieuw instellen",
  RESET_PASSWORD_NEW_LABEL: "Nieuw wachtwoord",
  RESET_PASSWORD_CONFIRM_LABEL: "Wachtwoord bevestigen",
  RESET_PASSWORD_PASSWORD_PLACEHOLDER: "Voer wachtwoord in",
  RESET_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER: "Voer bevestigingswachtwoord in",
  RESET_PASSWORD_SHOW_PASSWORD: "Toon wachtwoord",
  RESET_PASSWORD_SHOW_CONFIRM_PASSWORD: "Toon bevestigingswachtwoord",
  RESET_PASSWORD_PASSWORD_ERROR: "Voer een geldig wachtwoord in",
  RESET_PASSWORD_CONFIRM_PASSWORD_ERROR: "Wachtwoorden komen niet overeen",
  RESET_PASSWORD_SUBMIT_BUTTON: "Verzenden",

  REGISTER_USER_NAME: "Gebruikersnaam",
  REGISTER_ENTER_USERNAME: "Voer gebruikersnaam in",
  REGISTER_EMAIL_ADDRESS: "E-mailadres",
  REGISTER_ENTER_EMAIL: "Voer uw e-mailadres in",
  REGISTER_COUNTRY: "Land",
  REGISTER_PHONE_NUMBER: "Telefoonnummer",
  REGISTER_ENTER_MOBILE_NUMBER: "Voer uw mobiele nummer in",
  REGISTER_PASSWORD: "Wachtwoord",
  REGISTER_ENTER_PASSWORD: "Voer wachtwoord in",
  REGISTER_CONFIRM_PASSWORD: "Wachtwoord bevestigen",
  REGISTER_ENTER_CONFIRM_PASSWORD: "Voer bevestigingswachtwoord in",
  REGISTER_REFERRAL_CODE: "Verwijzingscode (optioneel)",
  REGISTER_ENTER_REFERRAL_CODE: "Voer verwijzingscode in",
  REGISTER_OTP: "OTP",
  REGISTER_ENTER_OTP: "Voer OTP in",
  REGISTER_AGREE_TERMS: "Ik begrijp het",
  REGISTER_TERMS_LINK: "Gebruiksvoorwaarden",
  REGISTER_PRIVACY_LINK: "Privacybeleid",
  REGISTER_BUTTON: "Registreren",
  REGISTER_ALREADY_REGISTER: "Al geregistreerd?",
  REGISTER_SIGN_IN: "Aanmelden",

  REFERRAL_HEAD_TITLE: "Verwijzing",
  REFERRAL_TOTAL_REFERRALS_LABEL: "Totaal aantal verwijzingen",
  REFERRAL_TOTAL_INCOME_LABEL: "Totaal verdiend verwijzingsinkomen",
  REFERRAL_TOTAL_USERS_INVEST_LABEL:
    "Totaal doorverwezen gebruikers investeren",
  REFERRAL_LINK: "Verwijzingslink",
  REFERRAL_LEVEL_INCOME: "Niveau-inkomen",
  REFERRAL_LEVEL_1_INCOME: "Niveau 1-inkomen",
  REFERRAL_LEVEL_2_INCOME: "Niveau 2-inkomen",
  REFERRAL_LEVEL_3_INCOME: "Niveau 3-inkomen",
  REFERRAL_HISTORY_LABEL: "Verwijzingsgeschiedenis",

  // BACKEND

  EMAIL_EXISTS: "E-mailadres bestaat al",
  PHONE_EXISTS: "Telefoonnummer bestaat al",
  INVALID_REFERRAL_CODE: "Ongeldige verwijzingscode",
  OTP_SENT: "OTP verzonden naar uw e-mailadres, voer een OTP in",
  EXPIRED_OTP: "Verlopen OTP",
  INVALID_OTP: "Ongeldige OTP",
  REGISTRATION_SUCCESS:
    "Registratie voltooid - Activeringslink verzonden naar uw e-mailadres",
  SOMETHING_WENT_WRONG: "Er is iets misgegaan",

  INVALID_EMAIL: "Voer een correct e-mailadres in",
  PHONE_NOT_EXISTS: "Telefoonnummer bestaat niet",
  IP_BLOCKED: "Uw IP-adres is geblokkeerd",
  ACCOUNT_LOCKED: "Uw account is nog steeds vergrendeld",
  ACCOUNT_BLOCKED: "Uw account is geblokkeerd. Probeer het later opnieuw",
  ACCOUNT_NOT_ACTIVATED: "Uw account is nog niet geactiveerd",
  LOGIN_ATTEMPTS_EXCEEDED:
    "U heeft te veel inlogpogingen gehad. Probeer het later opnieuw.",
  PASSWORD_INCORRECT: "Onjuist wachtwoord",
  OTP_SENT:
    "OTP verzonden naar uw e-mailadres, OTP is slechts 3 minuten geldig",
  INVALID_MOBILE_NUMBER: "Ongeldig telefoonnummer",
  OTP_SENT_MOBILE: "OTP succesvol verzonden, het is slechts 10 minuten geldig",
  OTP_EXPIRED: "OTP verlopen",
  INVALID_OTP: "Ongeldige OTP",
  NEED_TWO_FA: "Heb 2FA-code nodig",
  INVALID_TWO_FA: "Ongeldige 2FA-code",
  EXPIRED_TWO_FA: "Verlopen 2FA-code",
  OTP_IS_REQUIRED: "OTP is vereist",
  LOGIN_SUCCESS: "Login succesvol!",
  ERROR_SERVER: "Fout op server",

  EMAIL_NOT_EXISTS: "E-mailadres bestaat niet",
  CONFIRMATION_LINK_SENT:
    "Bevestigingslink is verzonden naar uw geregistreerde e-mail",
  SOMETHING_WENT_WRONG: "Er is iets misgegaan",

  INVALID_LINK: "Ongeldige link!",
  OLD_PASSWORD_CANT_BE_NEW_PASSWORD:
    "Het oude wachtwoord kan niet het nieuwe wachtwoord zijn!",
  PASSWORD_UPDATED_SUCCESSFULLY: "Wachtwoord succesvol bijgewerkt",
  SOMETHING_WENT_WRONG: "Er is iets misgegaan",

  USER_NOT_FOUND: "Gebruiker niet gevonden",
  INCORRECT_OLD_PASSWORD: "Oud wachtwoord onjuist",
  NEW_PASSWORD_CANT_BE_OLD_PASSWORD:
    "Nieuw wachtwoord kan niet het oude wachtwoord zijn!",
  PASSWORD_CHANGED_SUCCESSFULLY: "Wachtwoord succesvol gewijzigd",

  PROFILE_DETAILS_UPDATED_SUCCESSFULLY: "Profielgegevens succesvol bijgewerkt",
  PROFILE_UPDATED_SUCCESSFULLY: "Profiel succesvol bijgewerkt",
  SOMETHING_WENT_WRONG: "Er is iets misgegaan",

  DEACTIVATED: "Gedeactiveerd",
  ACTIVATED: "Geactiveerd",
  USER_STATUS_CHANGED_SUCCESSFULLY: "Gebruikersstatus succesvol gewijzigd",
  SOMETHING_WENT_WRONG: "Er is iets misgegaan",
  ACTIVATE_REGISTER_USER: "activate_register_user",
  ACTIVATION_MAIL_SENT_SUCCESSFULLY: "Activatie-e-mail succesvol verzonden",
  ERROR_ON_SERVER: "Fout op de server",
  NO_RECORD: "Geen records",
  UPDATED_SUCCESS: "Bijwerken gelukt",
  INVALID_USER: "Ongeldige gebruiker",
  INVALID_MOBILE_NO: "Voer een correct mobiel nummer in",
  RESEND_OTP_MOBILE:
    "Verificatiecode verzonden naar uw mobiele nummer, verificatiecode is slechts 10 minuten geldig",
  NEXT_OTP_AFTER_3_MINUTES: "Volgende verificatiecode na 3 minuten",
  RESEND_OTP_EMAIL:
    "Verificatiecode verzonden naar uw e-mailadres, verificatiecode is slechts 3 minuten geldig",
  NAME_FIELD_REQUIRED: "Naam veld is verplicht",
  USERNAME_ALPHABETS_SPACES: "Gebruikersnaam moet letters en spaties bevatten",
  USER_NOT_FOUND: "Gebruiker niet gevonden",
  PROFILE_UPDATED_SUCCESSFULLY: "Profiel succesvol bijgewerkt",
  INVALID_USER_DETAILS: "Ongeldige gebruikersgegevens",
  INVALID_TYPE: "Ongeldig type",
  CODE_VERIFIED: "Code geverifieerd",
  ERROR_ON_SERVER: "Fout op de server",
  INVALID_VERIFICATION_CODE: "Ongeldige verificatiecode",
  VERIFICATION_CODE_EXPIRED: "Verificatiecode verlopen",
  CODE_VERIFIED: "Code geverifieerd",
  SOMETHING_WENT_WRONG_TRY_AGAIN_LATER:
    "Er is iets misgegaan, probeer het later opnieuw",
  USERID_FIELD_IS_REQUIRED: "Gebruikers-ID veld is verplicht",
  FIRST_NAME_FIELD_IS_REQUIRED: "Voornaam veld is verplicht",
  LAST_NAME_FIELD_IS_REQUIRED: "Achternaam veld is verplicht",
  PHONE_CODE_FIELD_IS_REQUIRED: "Telefooncode veld is verplicht",
  PHONE_NUMBER_FIELD_IS_REQUIRED: "Telefoonnummer veld is verplicht",
  PHONE_NUMBER_IS_INVALID: "Telefoonnummer is ongeldig",
  ADDRESS_FIELD_IS_REQUIRED: "Adresveld is verplicht",
  COUNTRY_FIELD_IS_REQUIRED: "Landenveld is verplicht",
  CITY_FIELD_IS_REQUIRED: "Stadsveld is verplicht",
  POSTAL_CODE_FIELD_IS_REQUIRED: "Postcodeveld is verplicht",
  REQUIRED: "Verplicht",
  USERNAME_ALPHA_SPACE: "Gebruikersnaam moet letters en spaties bevatten",
  EMAIL_REQUIRED: "E-mailadres is verplicht",
  INVALID_EMAIL: "Ongeldig e-mailadres",
  PHONE_NO_REQUIRED: "Telefoonnummer is verplicht",
  COUNTRY_CODE_REQUIRED: "Landcode is verplicht",
  PASSWORD_REQUIRED: "Wachtwoord is verplicht",
  INVALID_PASSWORD_FORMAT:
    "Wachtwoord moet minstens één hoofdletter, één kleine letter, één cijfer, één speciaal teken bevatten en 6 tot 18 tekens lang zijn",
  PASSWORD_LENGTH_EXCEEDED:
    "Wachtwoord moet minstens één hoofdletter, één kleine letter, één cijfer, één speciaal teken bevatten en 6 tot 18 tekens lang zijn",
  CONFIRM_PASSWORD_REQUIRED: "Bevestiging van wachtwoord is verplicht",
  PASSWORD_MISMATCH: "Wachtwoord en bevestiging moeten overeenkomen",
  TERMS_REQUIRED: "Algemene voorwaarden zijn verplicht",
  OLD_PASSWORD_FIELD_IS_REQUIRED: "Oud wachtwoord is vereist",
  NEW_PASSWORD_FIELD_IS_REQUIRED: "Nieuw wachtwoord is vereist",
  PASSWORD_REQUIREMENTS_NOT_MET:
    "Wachtwoord moet minstens één hoofdletter, minstens één kleine letter, minstens één cijfer, minstens één speciaal teken bevatten en tussen de 6 en 18 tekens lang zijn",
  CONFIRM_NEW_PASSWORD_FIELD_IS_REQUIRED:
    "Bevestig nieuw wachtwoord is vereist",
  PASSWORD_AND_CONFIRM_PASSWORD_MUST_MATCH:
    "Nieuw wachtwoord en bevestig wachtwoord moeten overeenkomen",
  OLD_AND_NEW_PASSWORD_MUST_BE_DIFFERENT:
    "Oud en nieuw wachtwoord moeten verschillend zijn",
  AUTH_TOKEN_FIELD_IS_REQUIRED: "Authenticatietoken is vereist",
  CONFIRM_PASSWORD_FIELD_IS_REQUIRED: "Bevestig wachtwoord is vereist",
  NEW_AND_CONFIRM_PASSWORD_MUST_MATCH:
    "Nieuw wachtwoord en bevestig wachtwoord moeten overeenkomen",
  USER_ASSET_FIELD_IS_REQUIRED: "Gebruikersactivumveld is vereist",
  INVALID_TRANSACTION_ID: "Ongeldige transactie-id",
  REQUIRED: "Vereist",
  AMOUNT_FIELD_IS_REQUIRED: "Bedragveld is vereist",
  INVALID_AMOUNT: "Ongeldig bedrag",
  USER_ASSET_FIELD_IS_REQUIRED: "Gebruikersassetveld is vereist",
  INVALID_CURRENCY: "Ongeldige valuta",
  AMOUNT_FIELD_IS_REQUIRED: "Bedrag veld is verplicht",
  INVALID_TRANSACTION_ID: "Ongeldige transactie-ID",
  REQUIRED: "Vereist",
  INVALID_CURRENCY_ID: "Ongeldige valutaid",
  RECEIVER_ADDRESS_FIELD_IS_REQUIRED: "Ontvanger adresveld is verplicht",
  INVALID_ADDRESS: "Ongeldig adres",
  AMOUNT_FIELD_IS_REQUIRED: "Bedragsveld is verplicht",
  PLEASE_ENTER_VALID_AMOUNT: "Voer a.u.b. een geldig bedrag in",
  MIN_WITHDRAW_AMOUNT_MESSAGE:
    "Voer een opnamebedrag in dat groter is dan of gelijk is aan",
  OTP_STATUS_IS_REQUIRED: "OTP-status is vereist",
  INVALID_OTP_STATUS: "Ongeldige OTP-status",
  PLEASE_ENTER_OTP: "Voer a.u.b. een OTP in",
  THERE_IS_NO_DATA: "Er zijn geen gegevens",
  PLEASE_ENTER_AMOUNT_GREATER_THAN_OR_EQUAL_TO:
    "Voer het bedrag in dat groter is dan of gelijk is aan ",
  DEPOSIT_REQUEST_SENT_SUCCESSFULLY: "Depositoaanvraag succesvol verzonden",
  INVALID_CURRENCY_DATA: "Ongeldige valutagegevens",
  INVALID_WITHDRAW_CURRENCY_DATA: "Ongeldige opname valutagegevens",
  MIN_WITHDRAW_AMOUNT_REQUIRED:
    "Minimum opnamebedrag moet groter zijn dan of gelijk zijn aan ",
  MAX_WITHDRAW_AMOUNT_REQUIRED:
    "Opnamebedrag moet kleiner zijn dan of gelijk zijn aan ",
  INSUFFICIENT_BALANCE: "Onvoldoende saldo",
  WITHDRAW_AMOUNT_TOO_LOW: "Opnamebedrag te laag",
  OTP_SENT_SUCCESSFULLY: "OTP verzonden naar uw e-mailadres, Voer een OTP in",
  EXPIRED_OTP: "Verlopen OTP",
  INVALID_OTP: "Ongeldige OTP",
  WITHDRAW_REQUEST_SENT_SUCCESSFULLY: "Opnameverzoek succesvol verzonden",
  ERROR_ON_SERVER: "Fout op server",
  RECORD_NOT_FOUND: "Record niet gevonden!",
  GENERATED_SUCCESSFULLY: "Succesvol gegenereerd",
  STAKING_CURRENCY_NOT_EXISTS: "Stakingsvaluta bestaat niet",
  INVESTMENT_PLAN_ADDED_SUCCESSFULLY: "Investeringsplan succesvol toegevoegd.",
  STAKING_CURRENCY_NOT_EXISTS: "Stakingsvaluta bestaat niet",
  INVESTMENT_PLAN_UPDATED_SUCCESSFULLY: "Investeringsplan succesvol bijgewerkt",
  FETCH_SUCCESS: "Recuperación exitosa",
  MIN_AMOUNT_GREATER_THAN_OR_EQUAL:
    "Minimumbedrag moet groter zijn dan of gelijk aan ",
  AMOUNT_LESS_THAN_OR_EQUAL: "Bedrag moet kleiner zijn dan of gelijk aan ",
  INVALID_STAKING: "Ongeldige staking",
  INVALID_CURRENCY: "Ongeldige valuta",
  DEACTIVE: "Inactief",
  NO_RECORD: "Geen gegevens",
  INVALID_ASSET: "Ongeldig activum",
  INSUFFICIENT_BALANCE: "Er is niet genoeg activa op uw balans.",
  INVESTMENT_ORDER_ADDED_SUCCESSFULLY:
    "Investeringsorder succesvol toegevoegd.",
  KINDLY_CHOOSE_DURATION_DAYS: "Gelieve de duur in dagen te kiezen",
  NO_RECORD: "Er is geen record",
  ALREADY_CANCELLED: "Al geannuleerd",
  INVALID_CURRENCY: "Ongeldige valuta",
  RELEASED_SUCCESSFULLY: "Succesvol vrijgegeven",
  ALREADY_REDEMPTION_COMPLETED: "De terugkoop is al voltooid",
  INSUFFICIENT_AMOUNT: "Onvoldoende bedrag",
  ENTER_VALID_AMOUNT: "Voer een geldig bedrag in",
  EMAIL_ALREADY_VERIFIED:
    "Uw e-mailadres is al geverifieerd, u kunt nu inloggen",
  ACCOUNT_DEACTIVATED_BY_ADMIN:
    "Uw account is gedeactiveerd door de beheerder, neem contact op met de beheerder",
  EMAIL_VERIFIED_CAN_LOGIN:
    "Uw e-mailadres is geverifieerd, u kunt nu inloggen",
  AMOUNT_ZERO: "Bedrag kan niet nul zijn",
  AMOUNT_NEGATIVE: "Bedrag kan niet negatief zijn",
  AMOUNT_EXCEEDS_STAKED:
    "Bedrag moet minder dan of gelijk aan {{stakedAmount}} zijn",
  SUCCESS_COPY: "Succesvol gekopieerd naar het klembord",
  INTEREST_HISTORY: "Rente geschiedenis",
  SEE_MORE: "Meer zien",
  STRATEGY: "Strategie",
  PERIOD: "Periode",
  MANAGEMENT_FEE: "Beheervergoeding %",
  MINIMUM_INVESTMENT: "Minimum investering",
  AMOUNT: "Bedrag",
  CLOSE: "Sluiten",
  INVEST: "Investeren",
  MONTHLY: "Maandelijks",
  PROCESS: "Proces",
  UNLOCKED: "Ontgrendeld",
  LOCKED: "Vergrendeld",
  DAYS: "Dagen",
  LOGOUT_SUCCESS: "Succesvol uitgelogd",
  INVEST_DASHBOARD_ROI_PERCENT: "ROI Percentage",
  HOME: "Startpagina",
  INVEST: "Investeren",
  WALLET: "Portemonnee",
  REFERRAL: "Verwijzing",
  DASHBOARD: "Dashboard",
  LOGOUT: "Uitloggen",
  THERE_ARE_NO_RECORDS_TO_DISPLAY: "Er zijn geen records om weer te geven",
  TOTAL_REFERRAL_INCOME_EARNED: "Totaal verdiende verwijzingsinkomsten",
  TOTAL_REFERRAL_USERS_INVEST:
    "Totaal aantal doorverwezen gebruikers investeren",
  TYPE: "Type",
  TRX_ID: "Trx Id",
  TO_ADDRESS: "Naar Adres",
  FROM_ADDRESS: "Van Adres",
  CURRENCY: "Valuta",
  FEE: "Kosten",
};
