let key = {};

if (process.env.REACT_APP_MODE === "production") {
  console.log("Set Production Config")
  const API_URL = 'https://prodapi.mantis.network';

  key = {
    SITE_NAME: 'MANTIS',
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LfpgzklAAAAAKJaFXLk3NNnXTjD65rfvz7AXd6j",
    API_URL: `${API_URL}`,
    FRONT_URL: 'https://mantis.network',
    ADMIN_URL: 'https://controls.mantis.network',
    SOCKET_URL: `${API_URL}`,
    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE: "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
    IMAGE: {
      BANK_PROOF_URL_PATH: '/images/bank/',
    }
  };

} else if (process.env.REACT_APP_MODE === "demo") {
  console.log("Set Demo Config")
  const API_URL = "https://mantisapi.wearedev.team";

  key = {
    SITE_NAME: "MANTIS",
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LeHezUfAAAAAE_uuY_HFN5HoEVsQv8bpyC3xTat",
    API_URL: `${API_URL}`,
    FRONT_URL: "https://mantis-demo-frontend.pages.dev",
    ADMIN_URL: "https://mantis-demo-admin-panel.pages.dev",
    SOCKET_URL: `${API_URL}`,
    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
    IMAGE: {
      BANK_PROOF_URL_PATH: "/images/bank/",
    },
  };

} else {
  console.log("Set Development Config")
  const API_URL = 'http://localhost'
  const PORT = 2053

  key = {
    SITE_NAME: 'COPY UNIT',
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LeHezUfAAAAAE_uuY_HFN5HoEVsQv8bpyC3xTat",
    API_URL: `${API_URL}:${PORT}`,
    FRONT_URL: 'http://localhost:3000',
    ADMIN_URL: 'http://localhost:3001/admin',
    SOCKET_URL: `${API_URL}:${PORT}`,
    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE: "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
    IMAGE: {
      BANK_PROOF_URL_PATH: '/images/bank/',
    }
  };
}


export default key;