import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import DataTable from "react-data-table-component";

// import lib
import { toFixed, toFixedDown } from "../../lib/roundOf";
import { daysToMonths } from "../../lib/calculation";
import { encryptString } from "../../lib/cryptoJS";
import { useSelector } from "react-redux";
import { GetFilterStakeshistory } from "../../api/invest";
import { IncCntObjId } from "../../lib/generalFun";
import { momentFormat } from "../../lib/dateTimeHelper";
import { useTranslation } from "react-i18next";

const customStyles = {
  headCells: {
    style: {
      fontFamily: "Arial, sans-serif",
      fontSize: "15px", // Change font size
      fontWeight: "600", // Change font size
    },
  },
  cells: {
    style: {
      fontFamily: "Arial, sans-serif",
      fontSize: "15px", // Change font size
    },
  },
  pagination: {
    style: {
      fontFamily: "Arial, sans-serif",
      fontSize: "15px", // Change font size
    },
  },
};

export default function InvestmentDetail() {
  const { stackingData } = useSelector((state) => state);
  const { t } = useTranslation();
  // state
  const [loader, setLoader] = useState(false);
  const [record, setRecord] = useState({
    data: [],
    count: 0,
  });
  const [filter, setFilter] = useState({
    strategy: "",
    type: "ascend",
    page: 1,
    limit: 10,
  });

  const { strategy, type } = filter;

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let filterData = { ...filter, [name]: value };
    setFilter(filterData);
    fetchHistory(filterData);
  };

  // function
  function countDaysBetweenDates(startDate, endDate, duration) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const timeDifference = end - start;

    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    if (daysDifference >= 0 && duration > 0) {
      const count = duration - daysDifference;
      return count;
    }
    return duration;
  }

  const fetchHistory = async (reqData) => {
    try {
      const { status, loading, result } = await GetFilterStakeshistory(reqData);
      if (status == "success") {
        setRecord({
          count: result.length,
          data: result,
        });
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchHistory(filter);
  }, []);

  return (
    <div>
      <div className="row dash_box_row">
        <div className="col-lg-12">
          <div className="chart_box">
            <div className="inner_subtitle">
              <h2>{t("INVESTMENT_DETAILS_INVESTMENT_DETAILS_TITLE")}</h2>
              <div className="d-flex justify-content-between m-3">
                <div>
                  <label className="text-light">
                    {t("INVESTMENT_DETAILS_STRATEGY_LABEL")}
                  </label>
                  <select
                    className="form-select chart_option_btn"
                    name="strategy"
                    onChange={handleChange}
                  >
                    <option value="">
                      {t("INVESTMENT_DETAILS_ALL_OPTION")}
                    </option>
                    {stackingData &&
                      stackingData.length > 0 &&
                      stackingData.map((el) => {
                        return <option value={el._id}>{el.strategy}</option>;
                      })}
                  </select>
                </div>

                <div>
                  <label className="text-light">
                    {" "}
                    {t("INVESTMENT_DETAILS_DURATION_LABEL")}
                  </label>
                  <select
                    className="form-select chart_option_btn"
                    name="type"
                    onChange={handleChange}
                  >
                    <option value="ascend">
                      {t("INVESTMENT_DETAILS_LOW_OPTION")}
                    </option>
                    <option value="descend">
                      {t("INVESTMENT_DETAILS_HIGH_OPTION")}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div className="invest_details_row">
              {record &&
                record?.count > 0 &&
                record?.data.map((item, key) => {
                  return (
                    <div className="invest_details_single">
                      <div className="invest_details_top">
                        <div className="invest_details_icon">
                          {item.strategy}
                        </div>
                        {/* <h2>{toFixedDown(item.investAmount, 4)} {item.coin}</h2> */}
                        <div class="d-flex flex-column">
                          <h2>
                            {" "}
                            {t("INVESTMENT_DETAILS_ID_LABEL")}{" "}
                            {IncCntObjId(item?._id)}
                          </h2>
                          <h2>
                            {item?.investAmount} {item?.coin}
                          </h2>
                        </div>
                      </div>
                      <div className="invest_details_info">
                        <div>
                          <label>
                            {" "}
                            {t(
                              "INVESTMENT_DETAILS_TOTAL_INVESTMENT_PERIOD_LABEL"
                            )}
                          </label>
                          <h5>
                            {item?.period &&
                              toFixed(daysToMonths(item.period), 0)}{" "}
                            Months <label>({item?.period} Days)</label>
                          </h5>
                        </div>
                        <div>
                          <label>
                            {" "}
                            {t("INVESTMENT_DETAILS_REWARDS_EARNED_LABEL")}
                          </label>
                          <h5>
                            {item?.earnAmount
                              ? toFixedDown(item?.earnAmount, 2)
                              : 0}{" "}
                            {item?.coin}
                          </h5>
                        </div>
                      </div>
                      <div className="invest_details_info">
                        <div>
                          <label>
                            {" "}
                            {t("INVESTMENT_DETAILS_REMAINING_DAYS_LABEL")}
                          </label>
                          {/* <h5>{item?.duration} Days</h5> */}
                          <h5>
                            {countDaysBetweenDates(
                              item?.settleStartDate,
                              new Date(),
                              item?.duration
                            )}{" "}
                            Days
                          </h5>
                        </div>
                        <div>
                          <label>
                            {t("INVESTMENT_DETAILS_CAPITAL_GROWTH_LABEL")}
                          </label>
                          <h5>
                            {item?.capitalGrowth
                              ? toFixedDown(item?.capitalGrowth, 2)
                              : 0}{" "}
                            {item?.coin}
                          </h5>
                        </div>
                        <div>
                          <Link
                            to={`/investment-details/${encryptString(
                              item.stakeId,
                              true
                            )}`}
                            className="secondary_btn"
                          >
                            {t("INVESTMENT_DETAILS_VIEW_BUTTON_LABEL")}
                          </Link>
                        </div>
                      </div>
                      <div className="invest_details_info">
                        <label>
                          {" "}
                          {t("INVESTMENT_DETAILS_INVESTED_AT_LABEL")}
                        </label>
                        <h5>{momentFormat(item?.createdAt, "DD-MM-YYYY")} </h5>
                      </div>
                    </div>
                  );
                })}
            </div>
            {record && record?.count == 0 && (
              <div className="table-responsive primary_table_div">
                <DataTable
                  className="table primary_table"
                  columns={[]}
                  data={[]}
                  customStyles={customStyles}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
